import { DBSchema, IDBPDatabase, deleteDB, openDB } from "idb";
import { getDefaultStore } from "jotai";
import { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { DOMContentLoaded, proxyToRaw, sleep } from ".";
import Call from "./calls/Call";
import { CallId, CallMap, callsState, setCalls } from "./calls/callAtoms";
import { insertNewCallsIntoRecentCallsMap } from "./calls/callUtils";
import { getChatbotDirectory } from "./chatbots/chatbotAtoms";
import WebGwContact, { WebGwContactList } from "./helpers/WebGwContact";
import {
  getLocalMessagesCursor,
  removeLocalMessagesCursor,
  setLocalMessagesCursor,
} from "./helpers/localstorage";
import type NmsMessage from "./messaging/NmsMessage";
import { getContactsAsync, setLoadedContacts } from "./messaging/contactsAtoms";
import Conversation from "./messaging/conversation/Conversation";
import {
  ConversationId,
  ConversationMap,
  conversationsState,
} from "./messaging/conversation/ConversationState";
import {
  insertNewNmsMessagesIntoConversation,
  setConversationMessageAsSent,
} from "./messaging/conversation/conversationUtils/";
import { updateConversationInDatabase } from "./messaging/conversation/conversationUtils/updateConversationInDatabase";
import { deleteMessages } from "./messaging/deleteMessages";
import { fetchMessages } from "./messaging/fetchMessages";

const defaultStore = getDefaultStore();

interface DbSchema extends DBSchema {
  conversations: {
    key: ConversationId;
    value: ReturnType<(typeof Conversation)["prototype"]["serialize"]>;
  };
  calls: {
    key: CallId;
    value: ReturnType<(typeof Call)["prototype"]["serialize"]>;
  };
}

const dbName = "verse-nms-db";
const dbVersion = 2;

let deleteDbPromise: Promise<void> | null = null;
export async function deleteDbs() {
  console.debug("deleting", dbName, window.getVerseDb(), deleteDbPromise);

  if (deleteDbPromise) {
    return deleteDbPromise;
  }

  // close the db first, making sure all transactions go through. this stops the blocking method from being called
  if (window.getVerseDb) {
    (await window.getVerseDb()).close();
    closeDb(await window.getVerseDb());
  }

  deleteDbPromise = new Promise<void>((res) => {
    deleteDB(dbName, {
      blocked: (currentVersion) => {
        console.warn("blocking in delete", currentVersion);
        res();
      },
    }).then(res);
    removeLocalMessagesCursor();
  }).finally(() => {
    console.info("Done deleting DB, delete db promise set to null");
    deleteDbPromise = null;
  });

  _verseDb = null;

  return deleteDbPromise;
}

async function createDb() {
  await deleteDbPromise;
  if (await DOMContentLoaded()) {
    await sleep(25);
  } else {
    await sleep(50);
  }
  console.info("Opening DB");
  const db = await openDB<DbSchema>(dbName, dbVersion, {
    upgrade(db) {
      if (!db.objectStoreNames.contains("conversations")) {
        console.info("Creating conversation table");
        db.createObjectStore("conversations");
      }
      if (!db.objectStoreNames.contains("calls")) {
        console.info("Creating call table");
        db.createObjectStore("calls");
      }
    },
    blocked() {
      console.error("blocked on openDB.");
    },
    blocking() {
      console.error("blocking on openDB.", arguments);
    },
    terminated() {
      console.error("idb creation terminated.");
    },
  });
  return db;
}

function isDbClosed(db: IDBPDatabase<DbSchema>) {
  return !!db["_closed"]; // Using internal property of idb library
}
function closeDb(db: IDBPDatabase<DbSchema>) {
  db["_closed"] = true;
}

let _verseDb: Promise<IDBPDatabase<DbSchema>> | null = null;
declare global {
  interface Window {
    getVerseDb: () => Promise<IDBPDatabase<DbSchema>>;
  }
}
window.getVerseDb = async function (): Promise<IDBPDatabase<DbSchema>> {
  if (!_verseDb) {
    console.info("DB isn't created");
    _verseDb = createDb();
  }

  const db = await _verseDb;
  if (isDbClosed(db)) {
    console.info("Database is closed. Reopening...");
    _verseDb = createDb();
    return await _verseDb;
  }
  return db;
};

export async function getStoredConversations() {
  const conversations = new Map<ConversationId, Conversation>();
  const contactsPromise = getContactsAsync();
  let contacts: WebGwContactList;
  try {
    contacts = (await contactsPromise) || new WebGwContactList();
  } catch (e) {
    console.warn("contactsPromise failed", e);
    contacts = new WebGwContactList();
  }

  const tx = (await window.getVerseDb()).transaction("conversations");
  for await (const conversation of tx.store) {
    conversations.set(conversation.key, Conversation.from(conversation.value));
  }
  for (const convo of conversations) {
    //TODO change from id to looking for numbers for when group chat feature is introduced
    const phoneNumber = convo[0];
    const findContact = contacts.findWithNumber(phoneNumber);
    const phoneNumberExists = findContact
      ?.getAllPhoneNumbers()
      ?.includes(phoneNumber);
    if (phoneNumberExists && findContact) {
      convo[1].participants[0] = findContact;
    }
  }

  await tx.done;
  console.info("Loaded ", conversations.size, " conversations");
  return conversations.size ? conversations : undefined;
}

export async function getStoredCalls() {
  const calls = new Map<CallId, Call>();

  const tx = (await window.getVerseDb()).transaction("calls");
  for await (const call of tx.store) {
    calls.set(call.key, Call.from(call.value));
  }

  await tx.done;
  console.info("Loaded ", calls.size, " calls");
  return calls.size ? calls : undefined;
}

async function fetchNmsMessages(
  cursor?: string | null,
  messages: NmsMessage[] = []
): Promise<{ cursor: string; messages: NmsMessage[] } | null> {
  console.info(
    "Fetching messages from NMS, currently have",
    messages.length,
    "messages"
  );
  const messagesObj = await fetchMessages(cursor).catch((err) => {
    console.error("Failed to sync NMS messages", err);
    return null;
  });
  if (!messagesObj) {
    console.warn("NMS returned an invalid answer");
    return null;
  }

  const { messages: newMessages, creationCursor } = messagesObj;
  console.info(
    "Loaded",
    newMessages.length,
    "messages from NMS, continue loading..."
  );
  const findByid = (v) =>
    messages.find((o) => o.ObjectId === v.ObjectId) != null;

  if (newMessages.length === 0 || newMessages.every(findByid)) {
    console.log(
      "Done loading all messages from NMS, returning",
      messages.length,
      "messages"
    );
    return { cursor: creationCursor, messages };
  }

  return fetchNmsMessages(creationCursor, messages.concat(newMessages));
}

export function useInitNmsMessages(handleSyncData) {
  const initialized = useRef(false);
  useEffect(() => {
    async function syncData() {
      try {
        const msgCount = await syncStoredNmsInfo();
        if (msgCount !== 0) {
          await markEmptyImdnMessagesAsSent();
        }
      } catch (e) {
        console.error("useInitNmsMessages: error:", e);
        if (e instanceof TypeError) {
          // Retry logic for handling stored data conflicts
          await deleteDbs();
          await sleep(2000);
          await syncData();
        } else {
          throw new Error();
        }
      }
    }

    if (handleSyncData) {
      if (initialized.current) return;

      initialized.current = true;
      console.log("NmsDatabase: loading messages from network message store");

      toast.promise(
        syncData(),
        {
          loading: "Syncing data with server",
          success: "Synced data successfully",
          error: "Syncing data failed",
        },
        {
          style: {
            backgroundColor: "#2E3237",
            color: "#FFFFFF",
          },
        }
      );
    }
  }, [handleSyncData]);
}

async function markEmptyImdnMessagesAsSent() {
  for (const conversation of conversationsState.conversations.values()) {
    const messages = conversation
      .getMessages()
      .filter((message) => message.imdns?.imdn?.length === 0);
    let updateDatabase = false;

    messages.forEach((message) => {
      updateDatabase = true;
      console.log("Marking message ", message["imdn.Message-ID"], " as sent");

      if (message.Direction === "Out") {
        setConversationMessageAsSent(
          message["imdn.Message-ID"],
          message.To,
          message.Date,
          false
        );
      }
    });

    if (updateDatabase) {
      await updateConversationInDatabase(conversation.id);
    }
  }
}

export async function syncStoredNmsInfo() {
  try {
    console.info("Loading info from NMS");
    const contactsPromise = getContactsAsync();
    const chatbotDirectoryPromise = getChatbotDirectory();

    let contacts: WebGwContactList | null = null;
    try {
      contacts = (await contactsPromise) || null;
    } catch (e) {
      console.warn("contactsPromise failed", e);
    }

    if (contacts === null) {
      contacts = new WebGwContactList();
    }

    try {
      const chatbots = (await chatbotDirectoryPromise)[0];
      if (chatbots) {
        for (const cb of chatbots) {
          contacts.push(WebGwContact.fromChatbotInfo(cb));
        }
      }
      // updates react-query state. This makes refreshing on the contacts page possible
      setLoadedContacts(contacts);
    } catch (e) {
      console.warn("chatbotDirectoryPromise failed", e);
    }

    console.info(
      "Loaded",
      contacts.length,
      "contacts from chatbots & contacts"
    );

    const [messages, calls] = await Promise.all([
      fetchNmsMessages(getLocalMessagesCursor()),
      getStoredCalls(),
    ]);

    const syncedMessages = messages?.messages;
    const cursorMessages = messages?.cursor || "";

    console.log("messages fetched in total -> ", syncedMessages?.length);

    if (syncedMessages && syncedMessages.length) {
      await insertNewNmsMessagesIntoConversation(syncedMessages, contacts);

      const newCalls = new Map(calls);
      insertNewCallsIntoRecentCallsMap(syncedMessages, contacts, newCalls);
      setCalls(newCalls);
      await updateStoredCalls(newCalls);
      await updateStoredConversations(conversationsState.conversations);
      setLocalMessagesCursor(cursorMessages);
      return syncedMessages.length;
    }

    return 0;
  } catch (error) {
    removeLocalMessagesCursor();
    throw error;
  }
}

async function updateStoredConversations(newConversations: ConversationMap) {
  const db = await window.getVerseDb();

  // add the updated conversations to the idb
  const tx1 = db.transaction("conversations", "readwrite");
  await Promise.all(
    newConversations
      .entries()
      .map(([conversationId, conversation]) =>
        tx1.store.put(proxyToRaw(conversation.serialize()), conversationId)
      )
  );
  await tx1.done;
}

async function updateStoredCalls(newCalls: CallMap) {
  const db = await window.getVerseDb();

  // add the updated conversations to the idb
  const tx2 = db.transaction("calls", "readwrite");
  await Promise.all(
    newCalls
      .entries()
      .map(([callId, call]) =>
        tx2.store.put(proxyToRaw(call.serialize()), callId)
      )
  );
  await tx2.done;
}

export async function deleteStoredConversation(conversation: Conversation) {
  const db = await window.getVerseDb();

  // delete the conversation from the idb
  const tx1 = db.transaction("conversations", "readwrite");
  await tx1.store.delete(conversation.id);
  await tx1.done;
}

export async function deleteStoredCall(callId: string) {
  const db = await window.getVerseDb();
  const tx2 = db.transaction("calls", "readwrite");
  await tx2.store.delete(callId);
  await tx2.done;

  callsState.calls.delete(callId);
  callsState.mapVersion++;
}

export async function deleteStoredCalls(callIds: string[]) {
  callIds.forEach((callId: string) => {
    deleteStoredCall(callId);
  });
}

export async function deleteConversation(
  conversation: Conversation
): Promise<boolean> {
  console.log("Deleting message inside conversation : ", conversation);
  let result: boolean | undefined = false;
  try {
    result = await deleteMessages(
      conversation.getMessages().map((nmsMessage) => {
        nmsMessage.destruct();
        return nmsMessage["imdn.Message-ID"];
      })
    );

    if (result) {
      deleteStoredConversation(conversation);
      conversationsState.conversations.delete(conversation.id);
    }

    console.log("Result deleting conversation -> ", result);
  } catch (err) {
    console.log("Error deleting conversation -> ", err);
  }

  return !!result;
}
