import {
  DeleteOverlayBodyCSS,
  DeleteOverlayButtonCSS,
  DeleteOverlayCSS,
  DeleteOverlayCloseButtonCSS,
} from "@/pages/conversationsScreen/ConversationScreen.style";
import { colors } from "@/styles/global.styles";
import CloseIcon from "@mui/icons-material/Close";
import PopupOverlay from "./PopupOverlay";
type ConfirmationPopupProps = {
  togglePopup: () => void;
  title: string;
  confirmationMessage: string;
  handleAction: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  primaryButtonText?: string;
  closeButtonActive?: Boolean;
  secondaryButtonActive?: Boolean;
  forOdienceScreen?: boolean;
};

const ConfirmationPopup = ({
  togglePopup,
  title,
  confirmationMessage,
  handleAction,
  primaryButtonText,
  closeButtonActive = true,
  secondaryButtonActive = true,
  // TODO - for now consider this instead of passing a custom css, mainly because odience has its own file and we will refactor to discard it at some point
  forOdienceScreen = false,
}: ConfirmationPopupProps) => {
  return (
    <PopupOverlay forOdienceScreen={forOdienceScreen}>
      <div css={DeleteOverlayCSS}>
        <div css={DeleteOverlayBodyCSS}>
          <h3
            style={
              forOdienceScreen
                ? {
                    fontWeight: "bold",
                    fontSize: "1.2em",
                  }
                : {}
            }
          >
            {title}
          </h3>
          {closeButtonActive && (
            <button css={DeleteOverlayCloseButtonCSS} onClick={togglePopup}>
              <CloseIcon />
            </button>
          )}
        </div>

        <div css={{ marginBottom: "2vh", width: "100%", textAlign: "left" }}>
          {confirmationMessage}
        </div>

        <div
          css={{
            display: "flex",
            flexDirection: "row",
            gap: "1vw",
            justifyContent: "end",
            width: "100%",
          }}
        >
          {secondaryButtonActive && (
            <button
              css={[
                DeleteOverlayButtonCSS,
                {
                  backgroundColor: colors.secondaryBackground,
                  color: colors.primaryTextColor,
                },
              ]}
              onClick={togglePopup}
            >
              Cancel
            </button>
          )}
          <button
            css={[
              DeleteOverlayButtonCSS,
              {
                backgroundColor: colors.primaryAccentColor,
                color: colors.primaryTextColor,
                fontSize: forOdienceScreen ? "1.6rem" : "initial",
              },
            ]}
            onClick={handleAction}
          >
            {primaryButtonText ? primaryButtonText : "Delete"}
          </button>
        </div>
      </div>
    </PopupOverlay>
  );
};

export default ConfirmationPopup;
