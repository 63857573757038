import { CapabilityHandler } from "../../utils/hooks/useWebgwSubscription";
import {
  getContactsAsync,
  setLoadedContacts,
} from "../messaging/contactsAtoms";
import { isSamePhoneNumber } from "../messaging/conversation/conversationUtils/phoneNumberUtils";
import { WebGwContactList } from "./WebGwContact";
import { updateLocalCacheCapabilities } from "./loginAndCaps";
import { CapabilityNotification } from "./notificationChannel";

export const handleCapabilitiesNotifications: CapabilityHandler = async (
  n: CapabilityNotification
) => {
  console.log("Received caps for user", n.contactServiceCapabilities.uri);

  // awaiting prevents race condition of receiving caps before contacts come in
  const fetchedContacts = await getContactsAsync();

  const updatedContacts = fetchedContacts?.map((webgwContact) => {
    for (const phoneNumber of webgwContact.getAllPhoneNumbers()) {
      if (isSamePhoneNumber(phoneNumber, n.contactServiceCapabilities.uri)) {
        // Create a new object with the updated attribute
        let updatedWebgwContact = webgwContact;
        updatedWebgwContact.isVerse = true;
        updatedWebgwContact.caps =
          n.contactServiceCapabilities.serviceCapability!;

        return updatedWebgwContact;
      }
    }

    return webgwContact;
  }) as WebGwContactList;
  setLoadedContacts(updatedContacts);

  updateLocalCacheCapabilities(
    n.contactServiceCapabilities.uri,
    n.contactServiceCapabilities.serviceCapability
  );
};
