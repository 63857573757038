import { LatLng } from "@/components/shared/Map";
import { getDefaultStore } from "jotai";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { DEFAULT_COUNTRY } from "./constants";
import { fetchAndStoreUserLocationCountryCode } from "./helpers/loginAndCaps";

export function generateMapUrl(
  latLng: LatLng,
  label = "",
  zoom = 16,
  width = 600,
  height = 300
): string {
  return `https://maps.googleapis.com/maps/api/staticmap?&markers=label${label}:%7C${latLng.lat},${latLng.lng}&center=${latLng.lat},${latLng.lng}&zoom=${zoom}&size=${width}x${height}&key=AIzaSyAYRO-TUL50Di8RwcwBjefkZQ0E1N1m9p8`;
}

export function getLocationInfoByCoordinates(
  map: google.maps.Map,
  latLng: LatLng,
  callback: (results: google.maps.places.PlaceResult | null) => void
) {
  new window.google.maps.Geocoder().geocode(
    { location: latLng },
    (results, status) => {
      if (status === "OK") {
        if (results && results.length > 0) {
          getLocationInfoByPlaceId(map, results[0].place_id, callback);
        } else {
          console.error("Geocoder did not find any result");
        }
      } else {
        console.error("Geocoder failed due to: ", status);
      }
    }
  );
}

export function getLocationInfoByPlaceId(
  map: google.maps.Map,
  placeId: string,
  callback: (results: google.maps.places.PlaceResult | null) => void
) {
  const request = {
    placeId: placeId,
    fields: ["name", "formatted_address", "geometry"],
  };

  new window.google.maps.places.PlacesService(map).getDetails(
    request,
    (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        callback(place);
      } else {
        console.error("Places service failed due to: ", status);
      }
    }
  );
}

export function generateLocationPayload(
  to: string,
  lat: number,
  lng: number,
  label = ""
) {
  return `<rcsenvelope entity="${to}" xmlns:gp="urn:ietf:params:xml:ns:pidf:geopriv10" xmlns:rpid="urn:ietf:params:xml:ns:pidf:rpid" xmlns="urn:gsma:params:xml:ns:rcs:rcs:geolocation" xmlns:opd="urn:oma:xml:pde:pidf:ext" xmlns:gml="http://www.opengis.net/gml">
    <rcspushlocation id="af8476ed" label="${label}">
    <rpid:time-offset>0</rpid:time-offset>
    <gp:geopriv>
        <gp:location-info>
            <gml:point srsName="urn:ogc:def:crs:EPSG::4326">
                <gml:pos>${lat} ${lng}</gml:pos>
            </gml:point>
        </gp:location-info>
    </gp:geopriv>
    <timestamp>${new Date().toISOString()}</timestamp>
  </rcspushlocation>
  </<rcsenvelope>`;
}

const defaultStore = getDefaultStore();
export async function getUserLocationCountry(store = defaultStore) {
  return (await store.get(userLocationCountryAtom)).data;
}

const queryUserLocationCountry = {
  staleTime: Infinity,
  queryKey: ["user-location-country"],
  queryFn: async () => {
    try {
      const country = await fetchAndStoreUserLocationCountryCode();
      country.country_code = `+${country.country_code}`;
      return country;
    } catch (e) {
      console.error(e);

      // Default North America
      return DEFAULT_COUNTRY;
    }
  },
} as const satisfies ReturnType<Parameters<typeof atomWithSuspenseQuery>[0]>;

export const userLocationCountryAtom = atomWithSuspenseQuery(
  (get) => queryUserLocationCountry
);
