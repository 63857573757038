import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { getDefaultStore } from "jotai";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { useEffect } from "react";
import { baseWebGwUrl } from "..";
import { fetchWithTimeout } from "../helpers/Utils";
import { getLocalAccessToken } from "../helpers/localstorage";
import { type Config } from "../helpers/loginAndCaps";
import { handleReloginMechanic } from "../hooks/useWebgwSubscription";
import { queryClient } from "../queryClient";

export const configAtom = atomWithSuspenseQuery((get) => query);

export function useConfig() {
  return useQuery(query).data;
}

export function useSuspenseConfig() {
  return useSuspenseQuery(query).data;
}

const defaultStore = getDefaultStore();

export async function getConfig(forceUpdate = false) {
  const configQuery = await defaultStore.get(configAtom);

  /**
   * If the atom does not return anything but marked as success in the query client (coming out from sleep, no internet connection),
   * atom.data will be null. At this point, even a refetch (from below) or invalidating the query wont update it, but query client will have the updated data.
   * Let's make sure we read the data from both places
   */
  let config =
    configQuery.data ||
    queryClient.getQueryData<Awaited<ReturnType<typeof query.queryFn>>>(
      query.queryKey
    ) ||
    null;

  if (!config || forceUpdate) {
    console.log(`Config is empty or forcing update (${forceUpdate})`);

    // For some reasons invalidating the query directly does not work, hence forcing here a refetch that will update the data in the query client
    config = (await configQuery.refetch()).data || null;
  }

  return config;
}

const staleTimeMs = 60 * 60 * 1000;

type ConfigRes = {
  config: Config;
  country: string; //"CA"
  uriTemplate: string; //"sip:<formatted-digits>@erl.rcs.st"
  version: string;
};
const query = {
  staleTime: 6 * staleTimeMs,
  queryKey: ["config"],
  retry: 2,
  queryFn: async () => {
    const accessToken = getLocalAccessToken();

    if (!accessToken) {
      console.error("No local access token");
      return null;
    }

    const response = await fetchWithTimeout(
      new URL(
        `/client/v1/anonymous/getconfig?access_token=${accessToken}`,
        baseWebGwUrl
      ),
      { method: "POST" }
    );
    if (!response?.ok) {
      if (response?.status === 403) {
        // Do not await here, the full relogin mechanism eventually call getConfig again, this may end up in a dead lock
        handleReloginMechanic(false);
      }

      return null;
    }

    const configRes = (await response?.json()) as ConfigRes;
    return configRes.config;
  },
  refetchOnReconnect: "always",
} as const satisfies Parameters<typeof useQuery>[0];

export async function checkAndRefetchConfig(
  forceUpdate = false
): Promise<Config | null> {
  // Check if configAtom is empty or stale
  let config = await getConfig();
  const lastRefreshTimestamp = localStorage.getItem("lastRefreshTimestamp");
  const staled =
    lastRefreshTimestamp &&
    Date.now() - parseInt(lastRefreshTimestamp, 10) >= staleTimeMs;

  if (!config || staled || forceUpdate) {
    console.log(`Config is empty or data is staled (${staled})`);
    // If configAtom is empty or stale, initiate the refetch
    config = await getConfig(true);
  }

  return config;
}

export function useConfigPeriodicRefetch() {
  useEffect(() => {
    const intervalId = setInterval(checkAndRefetchConfig, staleTimeMs);

    return () => clearInterval(intervalId);
  }, []);
}
