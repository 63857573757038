import { queryClient } from "@/utils/queryClient";
import {
  UndefinedInitialDataOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import type WebGwContact from "../helpers/WebGwContact";
import type { WebGwContactList } from "../helpers/WebGwContact";
import { wrapToWebGwContactList } from "../helpers/WebGwContact";
import { fetchContacts } from "../helpers/fetchContacts";

export const contactsQuery = {
  queryKey: ["contacts"],
  queryFn: async () => {
    const maxRetryAttempts = 5;
    const retryDelay = 5000;

    for (
      let retryAttempt = 0;
      retryAttempt < maxRetryAttempts;
      retryAttempt++
    ) {
      try {
        const contacts = await fetchContacts();
        if (contacts !== null) {
          return contacts;
        }
      } catch (error) {
        console.error("Unable to fetch contacts", error);
      }

      if (retryAttempt < maxRetryAttempts - 1) {
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
      }
    }

    return null;
  },
} as const satisfies UndefinedInitialDataOptions;
type QueryReturn = Awaited<ReturnType<typeof contactsQuery.queryFn>>;

export function getLoadedContacts() {
  return wrapToWebGwContactList(
    queryClient.getQueryData<QueryReturn>(contactsQuery.queryKey)
  );
}

export async function getContactsAsync() {
  return wrapToWebGwContactList(await queryClient.fetchQuery(contactsQuery));
}

export function setLoadedContacts(data: WebGwContactList) {
  return queryClient.setQueryData(contactsQuery.queryKey, data);
}

export function useContactsSuspense() {
  return wrapToWebGwContactList(useSuspenseQuery(contactsQuery).data);
}
export function useContacts() {
  return wrapToWebGwContactList(useQuery(contactsQuery).data);
}

export async function refreshContacts() {
  await queryClient.invalidateQueries({ queryKey: contactsQuery.queryKey });
}

export function useContactsChanged(
  savedContacts: WebGwContactList
): WebGwContact | null {
  const prevContactsRef = useRef<WebGwContactList>(savedContacts);

  useEffect(() => {
    prevContactsRef.current = savedContacts;
  }, [savedContacts]);

  for (const contact of savedContacts) {
    const prevContact = prevContactsRef.current.find(
      (prev) => prev.id === contact.id
    );

    if (!prevContact) {
      return contact;
    }
  }

  return null;
}

export function contactWasEdited(
  contact: WebGwContact | null
): WebGwContact | null {
  const prevContactRef = useRef<WebGwContact | null>(null);
  useEffect(() => {
    if (contact) {
      prevContactRef.current = contact;
    }
  }, [contact]);

  if (prevContactRef.current && contact) {
    const keys1 = Object.keys(prevContactRef.current).sort();
    const keys2 = Object.keys(contact).sort();

    const values1 = Object.values(prevContactRef.current).sort();
    const values2 = Object.values(contact).sort();

    if (
      JSON.stringify(keys1) !== JSON.stringify(keys2) ||
      JSON.stringify(values1) !== JSON.stringify(values2)
    ) {
      return contact;
    }
  }

  return null;
}
