import { useQuery } from "@tanstack/react-query";
import { atom, useSetAtom } from "jotai";
import { UndefinedInitialDataOptions } from "jotai-tanstack-query";
import { useEffect } from "react";
import { handleReloginMechanic } from "../hooks/useWebgwSubscription";
import { getLocalAccessToken } from "./localstorage";
import { DeviceIdentityData, login } from "./loginAndCaps";

export const loginQuery = {
  queryKey: ["login"],
  queryFn: async () => {
    const accessToken = getLocalAccessToken();
    if (!accessToken) {
      // Without an accessToken, /login is impossible.
      await handleReloginMechanic(false);
      return false;
    }

    console.log("loginQuery: calling Login");
    const loginResponse = await login(accessToken);
    console.log(
      "loginQuery: loginResponse:",
      loginResponse?.status,
      loginResponse?.statusText
    );
    if (!loginResponse?.ok) {
      await handleReloginMechanic(false);
      // we have to return false until the retry mechanism ends.
      return false;
    }
    // We return true if the first login function worked.
    return true;
  },
} as const satisfies UndefinedInitialDataOptions;

export const resultAtom = atom(false);

export const userAtom = atom("");

export const devicesAtom = atom<DeviceIdentityData[] | undefined>(undefined);

export const maxDevicesAtom = atom("");

export const isLoggedInAtom = atom(false);

export function useLogin() {
  const { data: _isLoggedIn } = useQuery(loginQuery);
  const isLoggedIn = !!_isLoggedIn;
  const setIsLoggedIn = useSetAtom(isLoggedInAtom);
  // We have to set the isLoggedInAtom here only if /login succeeds since we do not have any notification channel yet.

  // We can only know our registration state from the notification channel.
  // So the isLoggedInAtom will be set in useWebgwSubscription:handleClientStateNotification.
  useEffect(() => {
    setIsLoggedIn(isLoggedIn);
  }, [isLoggedIn]);
}
