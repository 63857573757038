import Tooltip from "@/components/shared/ToolTipDescription";
import { colors } from "@/styles/global.styles";
import { ReactionList } from "@/types/OmaNms";
import { useMessageActions } from "@/utils/helpers/MessageActionsUtils";
import { getLocalUser } from "@/utils/helpers/localstorage";
import NmsMessage from "@/utils/messaging/NmsMessage";
import Conversation from "@/utils/messaging/conversation/Conversation";
import { isSamePhoneNumber } from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import { deleteMessages } from "@/utils/messaging/deleteMessages";
import DeleteIcon from "@mui/icons-material/Delete";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import { IconButton } from "@mui/material";
import { atom, useSetAtom } from "jotai";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import EmojiPicker from "../ChatFooter/EmojiPicker";

export type MessageActionsApi = {
  handleMouseEnter: (
    event: React.MouseEvent<Element, MouseEvent>,
    actionsId: string
  ) => void;
  handleMouseLeave: (event: React.MouseEvent<Element, MouseEvent>) => void;
};

export type MessageActionsProps = {
  message: NmsMessage;
  beforeDelete: () => void | Promise<void>;
  direction: "In" | "Out";
  reactions?: ReactionList;
  conversation: Conversation;
};

export type Reply = {
  /** id ("imdn.Message-ID") of the message being replied to */
  id: string;
  /** the message being replied to (has the same id as the reply) */
  message?: NmsMessage;
  type: `${"+" | "-"}Reaction` | "Reply";
};

export type Reaction = Reply & {
  emoji?: string;
};

export const messageReplyAtom = atom<Reply | null>(null);

const MessageActions = forwardRef<MessageActionsApi, MessageActionsProps>(
  ({ message, beforeDelete, reactions, conversation }, ref) => {
    const { openActionsId, setOpenActionsId, toggleEmojiPicker, emojiPicker } =
      useMessageActions();
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const messageForDropDownRef = useRef<HTMLDivElement>(null);
    const setReply = useSetAtom(messageReplyAtom);
    const emojiPickerRef = useRef<HTMLDivElement>(null);
    const emojiMenuButtonRef = useRef<HTMLButtonElement>(null);
    const participants = conversation.participants;
    const isChatbot = participants[0]?.isChatbot;

    useImperativeHandle(ref, () => ({
      handleMouseEnter,
      handleMouseLeave,
    }));

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          messageForDropDownRef.current &&
          !messageForDropDownRef.current.contains(event.target as Node)
        ) {
          closeDropdown();
        }
      };

      if (isDropdownOpen) {
        document.addEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isDropdownOpen]);

    useEffect(() => {
      if (!emojiPicker) return;

      const handleClickOutside = (event: MouseEvent) => {
        if (
          emojiPickerRef.current &&
          !emojiPickerRef.current.contains(event.target as globalThis.Node) &&
          emojiMenuButtonRef.current &&
          !emojiMenuButtonRef.current.contains(event.target as globalThis.Node)
        ) {
          toggleEmojiPicker();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [emojiPicker]);

    useEffect(() => {
      if (!openActionsId && emojiPicker) {
        toggleEmojiPicker();
      }
    }, [emojiPicker, openActionsId]);

    const handleMouseEnter = (
      event: React.MouseEvent<Element, MouseEvent>,
      actionsId: string
    ) => setOpenActionsId(actionsId);

    const handleMouseLeave = () => {
      // If emoji picker is opened do not close the action menu
      if (emojiPicker) {
        return;
      }

      setOpenActionsId(null);
    };

    const closeDropdown = () => {
      setDropdownOpen(false);
      setOpenActionsId(null);
      toggleEmojiPicker();
    };

    const handleDeleteMessageButton = async (
      event: React.MouseEvent<HTMLButtonElement>,
      key: string
    ) => {
      event.stopPropagation();
      closeDropdown();
      await beforeDelete();
      conversation.removeMessages([key]);
      await deleteMessages([key]);
    };

    const handleAddOrRemoveEmoji = async (emoji: string) => {
      addOrRemoveEmoji(
        emoji,
        message["imdn.Message-ID"],
        conversation,
        reactions
      );

      toggleEmojiPicker();
    };

    return (
      <>
        <div
          ref={messageForDropDownRef}
          css={{
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0.5em",
            opacity: openActionsId ? 1 : 0,
            transition: "opacity 0.35s ease",
            height: "2em",
            visibility: openActionsId ? "visible" : "hidden",
          }}
        >
          <div
            css={{
              display: "flex",
              gap: "0.5em",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            {!isChatbot && (
              <Tooltip content="React">
                <IconButton
                  ref={emojiMenuButtonRef}
                  css={{
                    color: colors.secondaryTextColor,
                    "&:hover": { color: colors.primaryTextColor },
                  }}
                  onClick={toggleEmojiPicker}
                >
                  <TagFacesIcon
                    css={{
                      color: emojiPicker
                        ? colors.primaryAccentColor
                        : colors.secondaryTextColor,
                      "&:hover, &:focus": {
                        color: colors.primaryAccentColor,
                      },
                      transition: "color 0.25s ease",
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}

            {!isChatbot && (
              <Tooltip content="Reply">
                <IconButton
                  css={{
                    color: colors.secondaryTextColor,
                    "&:hover": { color: colors.primaryTextColor },
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setReply({
                      id: message["imdn.Message-ID"],
                      message,
                      type: "Reply",
                    });
                  }}
                >
                  <MessageOutlinedIcon
                    css={{
                      color: colors.secondaryTextColor,
                      "&:hover, &:focus": {
                        color: colors.primaryAccentColor,
                      },
                      transition: "color 0.25s ease",
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}

            {/* TODO: Enable edit button when we have the edit feature */}
            {/* <Tooltip content="Edit">
              <IconButton
                css={{
                  color: colors.secondaryTextColor,
                  "&:hover": { color: colors.primaryTextColor },
                }}
              >
                <EditIcon
                  css={{
                    color: colors.secondaryTextColor,
                    "&:hover, &:focus": {
                      color: colors.primaryAccentColor,
                    },
                    transition: "color 0.25s ease",
                  }}
                />
              </IconButton>
            </Tooltip> */}

            <Tooltip content="Delete">
              <IconButton
                onClick={(event) =>
                  handleDeleteMessageButton(event, message["imdn.Message-ID"])
                }
                css={{
                  color: colors.secondaryTextColor,
                  "&:hover": { color: colors.primaryTextColor },
                }}
              >
                <DeleteIcon
                  css={{
                    color: colors.secondaryTextColor,
                    "&:hover, &:focus": {
                      color: colors.primaryAccentColor,
                    },
                    transition: "color 0.25s ease",
                  }}
                />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        {emojiPicker && openActionsId && (
          <EmojiPicker
            ref={emojiPickerRef}
            handleEmojiClick={handleAddOrRemoveEmoji}
            visible={emojiPicker}
            uiMode="MESSAGE_REACTION"
          />
        )}
      </>
    );
  }
);

export const addOrRemoveEmoji = async (
  emoji: string,
  parentMessageId: string,
  conversation: Conversation,
  reactions?: ReactionList
) => {
  const localPhone = getLocalUser();

  if (!localPhone) {
    toast.error(`Error reacting with ${emoji}`);
    return;
  }

  // Check if local user already reacted with the same emoji
  const reactionExistsForLocal = reactions
    ? Object.entries(reactions)
        .filter(([key, _]) => {
          return key === emoji;
        })
        .map(([_, messages]) => {
          return messages.find((message) =>
            isSamePhoneNumber(message.From, localPhone)
          );
        })?.[0]
    : false;

  const reactionToSend: Reaction = {
    id: parentMessageId,
    type: `${reactionExistsForLocal ? "-" : "+"}Reaction`,
    emoji,
  };

  if (!(await conversation?.sendTextMessage(emoji, false, reactionToSend))) {
    toast.error(`Error with ${emoji}`);
    return;
  }

  // Remove case
  if (reactionExistsForLocal) {
    console.log("Removing reaction ", emoji);

    if (await deleteMessages([reactionExistsForLocal["imdn.Message-ID"]])) {
      conversation.removeMessage(reactionExistsForLocal);
    } else {
      toast.error(`Error removing reaction ${emoji}`);
    }
  }
};

export default MessageActions;
