import { paths } from "@/routerPaths";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getLocalAccessToken } from "../helpers/localstorage";
const tabIdKey = "tabId";
function getSessionTabId() {
  return sessionStorage.getItem(tabIdKey);
}
function setSessionTabId(tabId: string) {
  sessionStorage.setItem(tabIdKey, tabId);
}

const activeTabKey = "activeTab";
function getLocalActiveTab() {
  return localStorage.getItem(activeTabKey);
}
function setLocalActiveTab(tabId: string) {
  localStorage.setItem(activeTabKey, tabId);
}

const openTabsKey = "openTabs";
function getLocalOpenTabs() {
  return JSON.parse(localStorage.getItem(openTabsKey) || "[]") as string[];
}
function setLocalOpenTabs(openTabs: string[]) {
  localStorage.setItem(openTabsKey, JSON.stringify(openTabs));
}

const isTabRefreshingKey = "isTabRefreshing";
function getIsTabRefreshing() {
  return localStorage.getItem(isTabRefreshingKey) === "true";
}
function setIsTabRefreshing(isTabRefreshing: boolean) {
  if (isTabRefreshing) {
    localStorage.setItem(isTabRefreshingKey, "true");
  } else {
    localStorage.removeItem(isTabRefreshingKey);
  }
}

function isCurrentTabActive() {
  return getLocalActiveTab() === getSessionTabId();
}

export function useIsTabActive() {
  const initialIsTabActive = useMemo(() => isCurrentTabActive(), []);
  const [isTabActive, setTabActive] = useState(initialIsTabActive);

  const setCurrentTabActive = useCallback(() => {
    const tabId = getSessionTabId();
    setLocalActiveTab(tabId!);
    setTabActive(true);
  }, []);

  useEffect(() => {
    // Generate or retrieve a unique tab ID
    const tabId = generateActiveTab();

    const updateOpenTabs = () => {
      const openTabs = manageOpenTabs(tabId);

      // Check if the current tab is the active one
      const activeTab = getLocalActiveTab();
      if ((openTabs.length > 1 && activeTab !== tabId) || !getSessionTabId()) {
        setTabActive(false);
      } else if (!getLocalAccessToken()) {
        sessionStorage.removeItem(tabIdKey);
        console.warn("redirect(paths.onboarding)"); //hook conflict
        window.location.href = "#" + paths.onboarding;
      } else {
        setLocalActiveTab(tabId);
        setTabActive(true);
      }
    };

    const removeTab = () => {
      const openTabs = getLocalOpenTabs().filter((id) => id !== tabId);
      setLocalOpenTabs(openTabs);
      setIsTabRefreshing(true);
    };

    const handleUnload = () => {
      const isTabRefreshing = getIsTabRefreshing();
      setIsTabRefreshing(false);

      if (!isTabRefreshing) {
        removeTab();
      }
    };

    // if the active tab changes, this tab should no longer be active
    const handleStorage = (event: StorageEvent) => {
      if (event.key === activeTabKey) {
        updateOpenTabs();
      }
    };

    // Update the list of open tabs and check active tab
    updateOpenTabs();

    // Add event listeners to handle tab closure and visibility change
    window.addEventListener("beforeunload", removeTab);
    window.addEventListener("unload", handleUnload);
    window.addEventListener("visibilitychange", updateOpenTabs);
    window.addEventListener("storage", handleStorage);

    // Clean up event listeners and remove tab ID on unmount
    return () => {
      removeTab();
      setTabActive(false);
      window.removeEventListener("beforeunload", removeTab);
      window.removeEventListener("unload", handleUnload);
      window.removeEventListener("visibilitychange", updateOpenTabs);
      window.removeEventListener("storage", handleStorage);
    };
  }, []);

  return [isTabActive, setCurrentTabActive] as const;
}

function manageOpenTabs(tabId: string) {
  let openTabs = getLocalOpenTabs();

  // Filter out duplicate entries of the same tabId
  openTabs = [...new Set(openTabs)];

  if (!openTabs.includes(tabId)) {
    openTabs.push(tabId);
    setLocalOpenTabs(openTabs);
  }
  return openTabs;
}

function generateActiveTab() {
  const tabId = getSessionTabId() || new Date().getTime().toString();
  setSessionTabId(tabId);
  if (!getLocalActiveTab()) {
    setLocalActiveTab(tabId);
  }
  return tabId;
}

export function setNewCurrentTabActive() {
  const tabId = generateActiveTab();
  setLocalActiveTab(tabId);
  manageOpenTabs(tabId);
}
