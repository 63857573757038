import { isNmsLoggedIn, nmsLogin, setNmsLoggedIn } from ".";
import { baseNMSUrl } from "..";
import { Flag, OmaNmsSchema } from "../../types/OmaNms";
import { getLocalUser } from "../helpers/localstorage";
import NmsMessage from "./NmsMessage";

export async function fetchMessages(
  cursor?: string | null,
  retries = 0
): Promise<
  | {
      creationCursor: string;
      messages: NmsMessage[];
    }
  | undefined
> {
  const phoneNumber = getLocalUser();

  if (!phoneNumber) {
    console.error("No phone number provided");
    return;
  }

  const encodedPhoneNumber = encodeURIComponent("tel:" + phoneNumber);
  let result: any = undefined;

  if (!isNmsLoggedIn()) {
    result = await nmsLogin(encodedPhoneNumber);
    if (
      result &&
      retries < 1 &&
      (result.status === 401 || result.status === 403)
    ) {
      if (result.status === 403) {
        console.log("Clearing NMS cookie");
        const domain = baseNMSUrl
          .substring(0, baseNMSUrl.indexOf(":"))
          .replace("https://", "");
        document.cookie = `access_token=; Max-Age=-99999999; path=/; domain=${domain}`;
      }
      result = await nmsLogin(encodedPhoneNumber);
      if (result.ok) {
        console.debug("Successfully logged into nms");
        setNmsLoggedIn(true);
        retries = 0;
      } else {
        console.error("Failed to log into nms");
        setNmsLoggedIn(false);
        return;
      }
    }
  }

  const res = await fetch(
    new URL(
      `/nms/v1/store/${encodedPhoneNumber}/objects/operations/search`,
      baseNMSUrl
    ),
    {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        selectionCriteria: {
          maxEntries: 500,
          nonRecursiveScope: false,
          searchCriteria: {
            criterion: [
              {
                type: "CreatedObjects",
                value: cursor ?? "",
              },
            ],
          },
          sortCriteria: {
            criterion: [
              {
                type: "Date",
                order: "Ascending",
              },
            ],
          },
        },
      } as OmaNmsSchema),
    }
  );

  if (retries < 1 && (res.status === 401 || res.status === 403)) {
    if (res.status === 403) {
      console.log("Clearing NMS cookie");
      const domain = baseNMSUrl
        .substring(0, baseNMSUrl.indexOf(":"))
        .replace("https://", "");
      document.cookie = `access_token=; Max-Age=-99999999; path=/;domain=${domain};`;
    }
    const result2 = await nmsLogin(encodedPhoneNumber);
    if (result2?.ok) {
      console.debug("Successfully logged into nms");
      setNmsLoggedIn(true);
      retries = 0;
    } else {
      console.error("Failed to log into nms");
      setNmsLoggedIn(false);
      return;
    }
    return fetchMessages(cursor, retries + 1);
  }

  const nmsObj = (await res.json()) as OmaNmsSchema;
  const msgList = NmsMessage.toListFromOmaNmsSchema(nmsObj);
  if (!msgList) {
    console.error("Failed to parse messages", nmsObj);
    return;
  }
  return {
    creationCursor: nmsObj.objectList?.creationCursor ?? "",
    messages: msgList,
  };
}

export async function sendSeenFlag(objectId: string) {
  return sendFlag(objectId, "\\Seen");
}

async function sendFlag(objectId: string, flag: Flag) {
  const phoneNumber = getLocalUser();

  if (!phoneNumber) {
    console.error("No phone number provided");
    return;
  }

  const encodedPhoneNumber = encodeURIComponent("tel:" + phoneNumber);

  const res = await fetch(
    new URL(
      `/nms/v1/store/${encodedPhoneNumber}/objects/${objectId}/flags/${encodeURIComponent(flag)}`,
      baseNMSUrl
    ),
    {
      method: "PUT",
      credentials: "include",
    }
  );
  console.log("flags:", res.ok ? await res.json() : res.ok);
  return res;
}
