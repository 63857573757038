import { colors } from "@/styles/global.styles";
import { ease } from "@/utils/ease";
import { keyframes } from "@emotion/react";
import Color from "color";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ButtonBar, CallOverlayProps } from ".";
import pauseIcon from "../../assets/icon-pause.svg";
import WebGwContact from "../../utils/helpers/WebGwContact";
import { Avatar } from "../shared/Avatar";
import Timer from "../shared/Timer";
import { CloseOverlayButtonBox, Overlay, overlayTransition } from "./Overlay";

type OverlayProps = CallOverlayProps & {
  videoRef?: React.RefObject<HTMLVideoElement>;
  outgoingVideoRef?: React.RefObject<HTMLVideoElement>;
  rotationDegree?: number;
};

const VIDEO_WIDTH = 1280;
const VIDEO_HEIGHT = 720;

function OnHoldIndicator({ onHold }: { onHold: boolean }) {
  return (
    <AnimatePresence>
      {onHold && (
        <motion.div
          css={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 14, 26, 0.8)",
            backdropFilter: "blur(10px)",
            zIndex: 1,
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: overlayTransition }}
          transition={{ duration: 0.75, ease: ease }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "2vh",
              height: "100%",
              color: colors.primaryTextColor,
            }}
          >
            <img css={{ height: "5em" }} src={pauseIcon} />
            <div
              css={{
                borderRadius: "10em",
                padding: "0.8em",
                backgroundColor: new Color(colors.secondaryAccentColor)
                  .alpha(0.6)
                  .toString(),
                // pulse background color
                animation: `${keyframes({
                  "50%": {
                    backgroundColor: new Color(colors.secondaryAccentColor)
                      .alpha(0.4)
                      .toString(),
                  },
                })} 2s ease-in-out infinite`,
              }}
            >
              Your call is currently on hold...
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

function VideoContactAvatar({
  contact,
  underAvatar,
}: {
  contact: WebGwContact;
  underAvatar?: React.ReactNode;
}) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "1.5em",
      }}
    >
      <Avatar
        contact={contact}
        style={
          contact.photo || !contact.initials
            ? { width: "3.125em", height: "3.125em" }
            : { width: "2em", height: "2em", fontSize: "1.9535em" }
        }
      />
      <div
        css={{
          whiteSpace: "nowrap",
          color: "white",
          display: "flex",
          flexDirection: "column",
          gap: "0.75em",
        }}
      >
        <span
          css={{
            fontSize: "2em",
            textShadow: "2px 2px 6px rgb(0,0,0,0.5)",
          }}
        >
          {contact.noNameReturnPhoneNumber()}
        </span>
        <span>{underAvatar}</span>
      </div>
    </div>
  );
}

export function VideoCallOverlay({
  isFullScreen,
  toggleFullScreen,
  open,
  callActive,
  callMuted,
  rotationDegree,
  contact,
  videoRef,
  outgoingVideoRef,
  muteCall,
  endCall,
  time,
  showNotImplemented,
  isPaused,
}: OverlayProps) {
  const [isHovered, setIsHovered] = useState(false);
  const [displayStats, setDisplayStats] = useState(false);
  useLayoutEffect(() => {
    setIsHovered(false);
  }, [isFullScreen]);
  const [isButtonVisible, _setIsButtonVisible] = useState(true);
  const [videoStats, setVideoStats] = useState<any>();

  const TIMEOUT_HIDE_BUTTONS_MS = 2000;
  const hideButtonsTimer = useRef<number>();
  const setIsButtonVisible = (val: typeof isButtonVisible) => {
    _setIsButtonVisible(val);
    if (callActive) {
      clearTimeout(hideButtonsTimer.current);
      hideButtonsTimer.current = +setTimeout(() => {
        _setIsButtonVisible(false);
      }, TIMEOUT_HIDE_BUTTONS_MS);
    }
  };

  useLayoutEffect(() => {
    // Buttons are visible by default
    if (open) {
      setIsButtonVisible(true);
    }
  }, [open]);

  const handleOnMouseMove = () => {
    if (!callActive) {
      return;
    }

    setIsButtonVisible(true);
  };

  useEffect(() => {
    const handleResize = () => {
      const video = videoRef?.current;
      const outgoingVideo = outgoingVideoRef?.current;
      const videoContainer = video?.parentElement?.parentElement?.parentElement;

      if (!video || !videoContainer || !outgoingVideo) return;

      const containerWidth = videoContainer.offsetWidth;
      const containerHeight = videoContainer.offsetHeight;

      // Calculate scaling factor based on container size
      const scaleX = containerWidth / VIDEO_WIDTH;
      const scaleY = containerHeight / VIDEO_HEIGHT;

      // Adjust video dimensions based on rotation and fullscreen state
      const videoWidth =
        rotationDegree === 270 || rotationDegree === 90
          ? containerHeight
          : containerWidth;

      const videoHeight =
        rotationDegree === 270 || rotationDegree === 90
          ? containerWidth
          : containerHeight;

      // Set video size and alignment
      video.style.width = `${videoWidth}px`;
      video.style.height = `${videoHeight}px`;
      video.parentElement.style.justifyContent =
        rotationDegree === 270 || rotationDegree === 90 ? "start" : "center";

      // Adjust outgoing video size when call is not active
      if (!callActive) {
        outgoingVideo.style.width = `${VIDEO_WIDTH * scaleX}px`;
        outgoingVideo.style.height = `${VIDEO_HEIGHT * scaleY}px`;
      }

      // In pip mode, the video takes the full parent size. Hack to use the same border to prevent changing the UI.
      if (!isFullScreen) {
        video.style.borderRadius = getComputedStyle(
          video.parentElement.parentElement
        ).getPropertyValue("border-radius");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [
    videoRef,
    outgoingVideoRef,
    rotationDegree,
    isFullScreen,
    callActive,
    open,
  ]);

  return (
    <Overlay
      open={open}
      isFullScreen={isFullScreen}
      onMouseMove={handleOnMouseMove}
      onDoubleClick={() => {
        if (!isFullScreen) toggleFullScreen?.();
      }}
      onBackgroundDoubleClick={toggleFullScreen}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        css={{
          borderRadius: "10px",
          overflow: "hidden",
          boxShadow: "0px 4px 8px #00000029",
          backgroundColor: colors.primaryBackgroundLighter,
        }}
        style={
          isFullScreen
            ? {
                width: `${VIDEO_WIDTH}px`,
                height: `${VIDEO_HEIGHT}px`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                alignItems: "center",
              }
            : { width: "320px", height: "180px" }
        }
      >
        <OnHoldIndicator onHold={isPaused} />
        <div
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
            alignItems: "center",
            top: "0",
            bottom: "0",
          }}
        >
          <video
            ref={videoRef}
            autoPlay
            playsInline
            muted
            controls={false}
            disablePictureInPicture
            css={{
              objectFit: "contain",
              position: "relative",
              backgroundColor: colors.primaryBackgroundLighter,
              pointerEvents: "none",
            }}
            style={{ transform: `rotate(${rotationDegree ?? 0}deg)` }}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
          />
        </div>
      </div>

      {/* Picture-in-Picture (PIP)  only show when phone call is answered*/}
      <div
        style={{
          position: "absolute",
          backgroundColor: "rgb(0,0,0,0.25)",
          borderRadius: "10px",
          overflow: "hidden",
          zIndex: 2,
          ...(!callActive
            ? {
                left: "0px",
                bottom: "0px",
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                alignItems: "center",
              }
            : {
                left: "40px",
                bottom: "40px",
                width: "200px",
                height: "150px",
              }),
          ...(!isFullScreen &&
            callActive && {
              display: "none",
            }),
        }}
      >
        <video
          ref={outgoingVideoRef}
          autoPlay
          playsInline
          muted
          controls={false}
          disablePictureInPicture
          style={{
            objectFit: "cover",
            pointerEvents: "none",
            ...(callActive && { width: "100%", height: "100%" }),
          }}
          onMouseDown={(e) => {
            e.preventDefault();
          }}
        />
      </div>

      {!isFullScreen ? (
        <motion.div
          css={{
            position: "absolute",
            bottom: "0",
            left: "0",
            padding: "0.5em",
            opacity: "1",
            zIndex: 3,
          }}
          initial={{ y: "100%" }}
          animate={{ y: isButtonVisible && isHovered ? 0 : "100%" }}
        >
          <ButtonBar
            callActive={callActive}
            callMuted={callMuted}
            muteCall={muteCall}
            endCall={endCall}
            showNotImplemented={showNotImplemented}
            isFullScreen={isFullScreen}
          />
        </motion.div>
      ) : (
        <>
          <div
            css={{
              top: "2.5em",
              left: "2.5em",
              position: "absolute",
              display: "flex",
              gap: "2em",
              color: colors.primaryTextColor,
              zIndex: 3,
            }}
          >
            {contact && (
              <VideoContactAvatar
                contact={contact}
                underAvatar={!callActive ? "Calling..." : <Timer time={time} />}
              />
            )}
          </div>
          <motion.div
            style={{
              minHeight: "100%",
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: isButtonVisible ? 1 : 0 }}
          >
            <div
              style={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                bottom: "40px",
                zIndex: 3,
              }}
            >
              <ButtonBar
                isFullScreen
                callActive={callActive}
                callMuted={callMuted}
                muteCall={muteCall}
                endCall={endCall}
                showNotImplemented={showNotImplemented}
              />
            </div>
          </motion.div>
        </>
      )}

      <CloseOverlayButtonBox
        isFullScreen={isFullScreen}
        isHovered={isHovered}
        toggleFullScreen={toggleFullScreen}
      />
    </Overlay>
  );
}
