import axios from "axios";
import CryptoJS from "crypto-js";
import { baseFthttpUrl } from "..";
import { getConfig } from "../messaging/configAtoms";
import { createThumbnail } from "../messaging/conversation/conversationUtils";

export async function fetchWithDigestAuthentication(
  method: "POST" | "GET" | "PUT" | "DELETE",
  file: File,
  uploadThumbnail: boolean,
  progressCallback?: (progress: number) => void
) {
  const config = await getConfig();
  if (!config) {
    console.error("Undefined config");
    throw new Error();
  }
  console.info("Current FT-HTTP URL is :", baseFthttpUrl);
  const username = config["application/3gpp_ims/ext/gsma/username"];
  const password = config["application/3gpp_ims/ext/gsma/userpwd"];
  const url = new URL(``, baseFthttpUrl);

  const formData = new FormData();
  formData.append("file", file);

  // Add thumbnail if file is an image
  if (uploadThumbnail && file.type.startsWith("image/")) {
    const thumbnail = await createThumbnail(file);
    formData.append("thumbnail", thumbnail);
  }

  const responseAuth = await fetch(url, {
    method: method,
    mode: "cors",
    referrerPolicy: "no-referrer-when-downgrade",
    cache: "no-store",
  });

  // Extract the WWW-Authenticate header
  const wwwAuthenticateHeader = responseAuth.headers.get("WWW-Authenticate");
  if (!wwwAuthenticateHeader) {
    throw new Error("Digest authentication header not found");
  }

  const realm = /realm="(.*?)"/.exec(wwwAuthenticateHeader)![1];
  const nonce = /nonce="(.*?)"/.exec(wwwAuthenticateHeader)![1];
  const cnonce = Math.random().toString(36).substring(2, 10);

  // Create a response hash as per the Digest Authentication specification
  const HA1 = CryptoJS.MD5(`${username}:${realm}:${password}`).toString();
  const HA2 = CryptoJS.MD5(`${method}:${url.pathname}`).toString();
  const responseHash = CryptoJS.MD5(`${HA1}:${nonce}:${HA2}`).toString();

  // Construct the Authorization header
  const authHeader = `Digest username="${username}", realm="${realm}", nonce="${nonce}", uri="${url.pathname}", cnonce="${cnonce}", response="${responseHash}"`;
  const axiosApi = axios.create();

  if (progressCallback && (method === "POST" || method === "PUT")) {
    axiosApi.interceptors.request.use((config) => {
      config.onUploadProgress = (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded / (progressEvent?.total ?? 0)) * 100
        );
        progressCallback(progress);
      };
      return config;
    });
  }

  return axiosApi.request({
    url: url.href,
    method,
    data: formData,
    headers: {
      Authorization: authHeader,
    },
  });
}

export async function upload(file: File): Promise<string | undefined> {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await fetchWithDigestAuthentication("POST", file, false);

    if (!response || response.status < 200 || response.status > 300) {
      throw new Error();
    }

    const payload = await response!.data;

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(payload, "text/xml");
    const dataElements = xmlDoc.getElementsByTagName("data");
    const urls = Array.from(dataElements).map((dataEl) =>
      dataEl.getAttribute("url")
    );

    return urls?.[0] || undefined;
  } catch (error) {
    console.error("Error uploading ", file);
  }
}

export async function download(url: string): Promise<Blob | null> {
  try {
    const res = await fetch(url);

    if (!res.ok) {
      throw new Error(`Fail to download ${url}`);
    }

    return await res.blob();
  } catch (error) {
    console.error(`Fail to download ${url}. Details:`, error);
    return null;
  }
}
