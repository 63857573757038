import { isChatbot } from "@/utils";
import { conversationsState } from "./ConversationState";
import {
  isPhoneNumberAGroupContributionId,
  isSamePhoneNumber,
} from "./conversationUtils/phoneNumberUtils";

export function findConversationByPhoneNumber(phoneNumber: string) {
  const isGroupChat = isPhoneNumberAGroupContributionId(phoneNumber);

  console.log(
    "Looking for conversation with ",
    isGroupChat ? "group contribution id" : "number",
    ":",
    phoneNumber
  );

  if (isGroupChat || isChatbot(phoneNumber)) {
    return conversationsState.conversations.get(phoneNumber);
  }

  const conversationId = conversationsState.conversations
    .keys()
    .find((conversationId) => {
      return isSamePhoneNumber(conversationId, phoneNumber);
    });

  return conversationId
    ? conversationsState.conversations.get(conversationId)
    : undefined;
}
