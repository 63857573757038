import { colors } from "@/styles/global.styles";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const EditConvoMenu = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "90%",
  color: colors.primaryTextColor,
  borderBottom: "1px solid",
  borderColor: colors.secondaryTextColor,
  paddingBottom: "1em",
});

export const ConvoMenuSections = styled.div({
  display: "flex",
  flexDirection: "row",
  gap: "1em",
  justifyContent: "center",
  alignItems: "center",
});

export const ConvoMenuItem = styled.div({
  display: "flex",
  gap: "0.3em",
});

export const DeleteOverlayCSS = css({
  "@media (max-width: 768px)": {
    width: "70vw",
  },
  width: "35vw",
  backgroundColor: colors.primaryBackground,
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "1em",
  color: "white",
  padding: "2.5vw",
  zIndex: 9999,
  maxHeight: "80%",
});

export const DeleteOverlayButtonCSS = css({
  textDecoration: "none",
  border: "none",
  borderRadius: "10px",
  fontSize: "1rem",
  padding: "1vh 1vw",
});

export const DeleteOverlayBodyCSS = css({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  zIndex: 9999,
});

export const DeleteOverlayCloseButtonCSS = css({
  background: "none",
  border: "none",
  color: "white",
});
