import * as MediaModel from "@/../generated-protos/api/common/model/types/v1/media";
import {
  Media as Media$,
  Media_ResizeConfiguration,
  Media_ResizeConfiguration_Mode,
} from "@/../generated-protos/api/common/model/types/v1/media";
import { ResizeMediaRequest } from "@/../generated-protos/api/media/grpc/media/v1/media";
import { Media } from "@/../generated-protos/api/media/model/resources/media/v1/media";
import { ChatMessage } from "@/components/chatScreen/chat/typings";
import { paths } from "@/routerPaths";
import { Imdn, ImdnInfo, OmaNmsSchema } from "@/types/OmaNms";
import { MediaServiceProvider, proxyToRaw } from "@/utils/";
import { insertCallFromNms } from "@/utils/calls/callUtils";
import { selectedChatbotAtom } from "@/utils/chatbots/chatbotAtoms";
import WebGwContact, { WebGwContactList } from "@/utils/helpers/WebGwContact";
import { getLocalUser } from "@/utils/helpers/localstorage";
import {
  ChatMessageStatusNotification,
  ComposingNotification,
  GroupChatIconNotification,
  NewGroupChatInvitationNotification,
  NewMessageNotification,
} from "@/utils/helpers/notificationChannel";
import { getWebRTC } from "@/utils/webrtc/webrtcUtils";
import { getDefaultStore } from "jotai";
import {
  getRemoteInfosFromImdnResourceUrl,
  GroupChatInfos,
  GroupChatParticipant,
  sendMessageStatus,
} from "../..";
import NmsMessage from "../../NmsMessage";
import { getLoadedContacts } from "../../contactsAtoms";
import Conversation, { ConversationGroupChatInfos } from "../Conversation";
import { conversationsState } from "../ConversationState";
import {
  autoOpenCurrentMessageNotificationAtom,
  currentMessageNotificationSenderAtom,
  keepPreviousMessageNotificationChatOverlayAtom,
  previousMessageNotificationSenderAtom,
  showNewMessageNotificationAtom,
} from "../conversationAtoms";
import { findConversationByPhoneNumber } from "../findConversationByPhoneNumber";
import { getTextAfterLastSlash, isSamePhoneNumber } from "./phoneNumberUtils";
import { updateConversationInDatabase } from "./updateConversationInDatabase";

const THUMBNAIL_SIZE = 100;

async function addMessageOrCreateConversation({
  phoneNumber,
  associatedContact,
  message,
  ignoreIdSet,
  contacts,
}: {
  phoneNumber: string;
  associatedContact?: WebGwContact;
  message: NmsMessage;
  ignoreIdSet: Set<string>;
  contacts: WebGwContactList;
}) {
  let conversation: Conversation;
  let isNew: boolean;
  let updateConversationInDb: Promise<void> | undefined;

  if (message.isGroupChatInfos()) {
    ({ conversation, isNew } = insertOrUpdateGroupChatInfos(
      phoneNumber,
      message["ConferenceUri"]!,
      message.getGroupChatInfos()!,
      contacts,
      false
    ));
  } else {
    ({ conversation, isNew, updateConversationInDb } = Conversation.getOrCreate(
      {
        phoneNumber,
        contactToLinkIfCreate: associatedContact,
        initialMessage: message,
      }
    ));
  }

  if (updateConversationInDb) {
    await updateConversationInDb;
  }

  if (!isNew && !message.isGroupChatInfos()) {
    const reactionType = message.getReactionType();

    switch (reactionType) {
      case "REMOVE":
        conversation.removeMessage(message, false);
        break;
      default:
        conversation.pushMessage(message, ignoreIdSet, false);
        break;
    }
  }
  ignoreIdSet.add(message["imdn.Message-ID"]);
}

export async function insertNewNmsMessagesIntoConversation(
  messages: NmsMessage[],
  contacts: WebGwContactList
) {
  const ignoreIdSet = new Set<string>();

  // assume messages are already sorted by date
  for (const message of messages) {
    const context = message["Message-Context"];

    const isGroupChat = context === "message/groupchat";

    const phoneNumber = isGroupChat
      ? message["Contribution-ID"]
      : message.Direction?.startsWith("In")
        ? message.From
        : message.To;

    if (!phoneNumber) {
      console.error(
        "Message does not have a From field or contribution id",
        message
      );
      continue;
    }

    if (
      context === "message/onetoone" ||
      context === "message/chatbot" ||
      isGroupChat
    ) {
      await addMessageOrCreateConversation({
        phoneNumber,
        associatedContact: isGroupChat
          ? undefined
          : contacts.findWithNumber(phoneNumber),
        ignoreIdSet,
        message,
        contacts,
      });
    } else if (context === "message/callhistory") {
      // Ignore call logs
    } else {
      console.error(`Unimplemented message context: '${context}'`, message);
    }
  }
}

export async function writeMessageToSelectedConversation(
  msg: Omit<ChatMessage, "status">,
  chatbotResponse = true
) {
  if (!msg.textMessage?.trim()) {
    console.error("Unimplemented: Message must have textMessage property", msg);
    return;
  }

  const conversation = conversationsState.selectedConversationId
    ? conversationsState.conversations.get(
        conversationsState.selectedConversationId
      )
    : undefined;
  if (!conversation) {
    throw new Error("No conversation selected");
  }

  await conversation.sendTextMessage(msg.textMessage, chatbotResponse);
}

function setImdn(
  type: ImdnInfo["type"],
  messageId: string,
  remote: string,
  date: Date,
  updateDatabase: boolean
) {
  return setConversationMessagePartial(
    messageId,
    {
      uploadProgress: undefined,
      imdns: {
        imdn: [
          {
            imdnInfo: [
              {
                type,
                date: date.toISOString(),
              },
            ],
            originalTo: remote,
          },
        ],
      },
    },
    Conversation.getOrCreate({ phoneNumber: remote }).conversation.id,
    updateDatabase
  );
}

export function setConversationMessageAsFailed(
  messageId: string,
  remote: string,
  date: Date,
  updateDatabase = true
) {
  return setImdn("Failed", messageId, remote, date, updateDatabase);
}

export function setConversationMessageAsSent(
  messageId: string,
  remote: string,
  date: Date,
  updateDatabase = true
) {
  return setImdn("stored", messageId, remote, date, updateDatabase);
}

export function setConversationMessagePartial(
  msgId: string,
  newMessage: Partial<NmsMessage>,
  conversationId: string,
  updateDatabase: boolean = true
) {
  const conversation = conversationsState.conversations.get(conversationId);
  if (!conversation) {
    return false;
  }
  const message = conversation
    .getMessages()
    .findLast((message) => message["imdn.Message-ID"] === msgId);
  if (!message) {
    return false;
  }
  message.merge(newMessage);

  // In case of a new message id, we need to discard the old one in the conversation
  if (newMessage["imdn.Message-ID"]) {
    conversation.replaceMessageId(msgId, message["imdn.Message-ID"]);
  }

  if (updateDatabase) {
    updateConversationInDatabase(conversation.id);
  }
  return true;
}
const defaultStore = getDefaultStore();

export async function handleNewNmsObject(nmsNotification: OmaNmsSchema) {
  const nmsMessages = NmsMessage.fromNmsWebsocketEvent(nmsNotification);
  (await nmsMessages)?.forEach(async (nmsMessage: NmsMessage) => {
    if (nmsMessage.getCallLog()) {
      // This means it is a call log NmsObject.
      getWebRTC()?.receivedCallLogNmsObject(nmsMessage);
      insertCallFromNms(nmsMessage);
    } else if (nmsMessage.isGroupChatInfos()) {
      const contributionId = nmsMessage["Contribution-ID"];
      const conferenceUri = nmsMessage["ConferenceUri"];

      const groupInfos = nmsMessage.getGroupChatInfos();

      if (!contributionId || !groupInfos || !conferenceUri) {
        console.log(
          "Missing contribution id or conference infos or conference uri"
        );
        return;
      }

      insertOrUpdateGroupChatInfos(
        contributionId,
        conferenceUri,
        groupInfos,
        getLoadedContacts()
      );
    } else {
      const senderAddress = nmsMessage.getSenderAddress();
      const contributionId = nmsMessage["Contribution-ID"];

      let contactToLinkIfCreate: WebGwContact | undefined = undefined;

      if (!nmsMessage.isGroupChat()) {
        contactToLinkIfCreate = WebGwContact.fromPhoneNumber(
          senderAddress,
          true
        );
      }

      const { conversation } = Conversation.getOrCreate({
        phoneNumber: contributionId || senderAddress,
        contactToLinkIfCreate,
      });

      const reactionType = nmsMessage.getReactionType();

      if (reactionType) {
        if (reactionType === "ADD") {
          conversation.pushMessage(nmsMessage);
        } else {
          conversation.removeMessage(nmsMessage);
        }
      } else {
        // Nms message is by definition a already stored message on the server, but sometimes imdn is not coming through, adding it by default here.
        if (nmsMessage.imdns?.imdn?.length === 0) {
          nmsMessage.imdns.imdn = [
            {
              imdnInfo: [
                {
                  type: "stored",
                  date: new Date().toISOString(),
                },
              ],
              originalTo: senderAddress,
            },
          ];
        }

        const alreadyRead = nmsMessage.isDisplayedNotificationSent;

        // Push message always return false if message already exists, we need to go through if message was read to potentially not show the notification
        if (
          (conversation.pushMessage(nmsMessage) || alreadyRead) &&
          nmsMessage.Direction === "In"
        ) {
          // Message was already read from another device, no need for notification
          if (alreadyRead) {
            // Clear out any pending notification
            if (pendingNotifications[senderAddress]) {
              clearTimeout(pendingNotifications[senderAddress]);
              pendingNotifications[senderAddress] = undefined;
            }
            return;
          }

          // Wait for potential read flag from another device to not show the notification
          pendingNotifications[senderAddress] = setTimeout(() => {
            displayNewIncomingMessageNotification(
              contributionId || senderAddress
            );
          }, 1000);
        }
      }
    }
  });
}

const insertOrUpdateGroupChatInfos = (
  contributionId: string,
  conferenceUri: string,
  groupChatInfos: GroupChatInfos,
  allUserContacts?: WebGwContactList | null,
  updateDatabase = true
): { conversation: Conversation; isNew: boolean } => {
  let contactAdminId: string | undefined;
  let isLocalUserAdmin = false;
  let participants: [WebGwContact, ...WebGwContact[]] | undefined;
  const localPhoneNumber = getLocalUser();

  if (groupChatInfos.participants) {
    participants = groupChatInfos.participants
      // We dont keep the local user in the participant list
      .filter((number) => {
        if (isSamePhoneNumber(number.phoneNumber, localPhoneNumber)) {
          isLocalUserAdmin = number.isAdmin;
          return false;
        }

        return true;
      })
      .map((number) => {
        const contact =
          allUserContacts?.findWithNumber(number.phoneNumber) ??
          WebGwContact.fromPhoneNumber(number.phoneNumber)!;

        if (number.isAdmin) {
          contactAdminId = contact.id;
        }

        return contact;
      }) as [WebGwContact, ...WebGwContact[]];
  }

  const conversationGroupChatInfos: ConversationGroupChatInfos = {
    contributionId,
    conferenceUri,
    subject: groupChatInfos.subject,
    iconUrl: groupChatInfos.iconUrl,
    // This is always true, because this method is always called based on a network event (recognized us as part of the group) or the local (can update group only if still joined)
    isLocalUserJoined: true,
    isLocalUserAdmin,
    contactAdminId,
    participants,
    date: groupChatInfos.date,
  };

  const { conversation, isNew } = Conversation.getOrCreate({
    phoneNumber: contributionId,
    contactToLinkIfCreate: participants,
    groupChatInfos: conversationGroupChatInfos,
  });

  // Updating existing conversation with group chat information
  if (!isNew) {
    conversation.updateGroupInformation(
      conversationGroupChatInfos,
      updateDatabase
    );
  }

  return {
    conversation,
    isNew,
  };
};

export function handleNewChatMessage(chatNotification: NewMessageNotification) {
  const newMessage = NmsMessage.fromWebgwNotification(chatNotification);

  if (newMessage.getCallLog()) {
    insertCallFromNms(newMessage);
  } else {
    const { senderAddress } = chatNotification;
    const remote = newMessage["Contribution-ID"] || senderAddress;

    let contactToLinkIfCreate: WebGwContact | undefined = undefined;

    if (!newMessage["Contribution-ID"]) {
      contactToLinkIfCreate = WebGwContact.fromPhoneNumber(senderAddress, true);
    }

    const { conversation, isNew } = Conversation.getOrCreate({
      phoneNumber: remote,
      initialMessage: newMessage,
      contactToLinkIfCreate,
    });

    const reactionType = newMessage.getReactionType();

    if (reactionType) {
      if (reactionType === "ADD") {
        conversation.pushMessage(newMessage);
      } else {
        conversation.removeMessage(newMessage);
      }
    } else {
      sendMessageStatus(
        remote,
        newMessage["imdn.Message-ID"],
        "Delivered"
      ).then((delivered) => {
        console.debug(
          "send message status result",
          delivered ? "delivered" : "failed"
        );
      });
      newMessage.setImdn("Delivered");

      let displayNotification = true;

      if (!isNew) {
        console.log("Pushing new message into conversation:", conversation.id);
        displayNotification = conversation.pushMessage(newMessage);

        // remove composer since the message was sent
        // TODO check if this is what should be done
        const composer = conversation.composers.find(
          ([composer]) => !!composer.filterContactOnPhone(senderAddress)
        );
        if (composer) {
          conversation.removeComposer(composer[0]);
        }
      }

      if (displayNotification) {
        displayNewIncomingMessageNotification(remote);
      }
    }
  }
}

export function handleNewGroupChatInvitation(
  groupChatInvitationNotification: NewGroupChatInvitationNotification
) {
  const contactsFromInvitation =
    groupChatInvitationNotification.invite_received.split(",");

  if (contactsFromInvitation.length === 0) {
    console.warn("Group chat invitation with no participants, ignoring.");
    return;
  }

  const localUser = getLocalUser();
  const participants: GroupChatParticipant[] = contactsFromInvitation.map(
    (phoneNumber) => {
      return {
        phoneNumber,
        isJoined: true,
        isAdmin: isSamePhoneNumber(
          phoneNumber,
          groupChatInvitationNotification.admin
        ),
      };
    }
  );

  if (localUser) {
    participants.push({
      phoneNumber: localUser,
      isJoined: true,
      isAdmin: isSamePhoneNumber(
        localUser,
        groupChatInvitationNotification.admin
      ),
    });
  }

  const groupInfos: GroupChatInfos = {
    iconUrl: groupChatInvitationNotification.icon,
    subject: groupChatInvitationNotification.subject,
    participants,
  };

  insertOrUpdateGroupChatInfos(
    groupChatInvitationNotification.group_id,
    "",
    groupInfos,
    getLoadedContacts()
  );
}

export function handleGroupChatIcon(
  groupChatNotification: GroupChatIconNotification
) {
  const groupInfos: GroupChatInfos = {
    iconUrl: groupChatNotification.icon,
  };

  insertOrUpdateGroupChatInfos(groupChatNotification.group_id, "", groupInfos);
}

/**
 * Display a chat screen overlay
 * @param senderAddress The sender address of the conversation
 * @returns
 */
export const displayChatScreenOverlay = (senderAddress: string) =>
  displayNewIncomingMessageNotification(senderAddress, true);

const selectedChatbotHasAddress = (senderAddress: string) =>
  !!defaultStore.get(selectedChatbotAtom)?.filterContactOnPhone(senderAddress);

const pendingNotifications = {};
/**
 * Displays a new incoming message notification
 * @param senderAddress The sender address of the conversation
 * @param autoOpen true to directly open the chat screen overlay and discard the notification
 */
export const displayNewIncomingMessageNotification = (
  senderAddress: string,
  autoOpen: boolean = false
) => {
  // We want to display the message notification on all screens except the message one
  defaultStore.set(
    showNewMessageNotificationAtom,
    !location.href.includes(paths.messages) &&
      !selectedChatbotHasAddress(senderAddress)
  );

  /**
   * We only manage one notification at a time, for the lastest message received.
   * When a new notification is received, there are 2 scenarios:
   * - User did not click on the reply button from the notification yet. In this case, we always replace the notification on the screen with the new one.
   * - User clicked on the reply button which opened a chat overlay dialog:
   *  1 - the new notification is from the same user, we dont display it
   *  2 - the new notification if from a different user, we display it
   */
  const latestSender = defaultStore.get(currentMessageNotificationSenderAtom);

  // New sender, we keep track of the previous one
  if (senderAddress !== latestSender) {
    defaultStore.set(keepPreviousMessageNotificationChatOverlayAtom, true);
    defaultStore.set(previousMessageNotificationSenderAtom, latestSender);
  }

  defaultStore.set(currentMessageNotificationSenderAtom, senderAddress);

  // Use a unique date instead of a boolean to re-render each time the component using the atom
  defaultStore.set(
    autoOpenCurrentMessageNotificationAtom,
    autoOpen ? Date.now() : 0
  );
};

export function handleIsComposing(chatNotification: ComposingNotification) {
  const contributionIdOrPhoneNumber =
    getTextAfterLastSlash(chatNotification?.link?.[0]?.href) ||
    chatNotification.senderAddress;

  findConversationByPhoneNumber(
    contributionIdOrPhoneNumber
  )?.handleComposingNotification(chatNotification);
}

export function handleMessageStatusNotification(
  chatMessageStatusNotification: ChatMessageStatusNotification["chatMessageStatusNotification"]
) {
  const href = chatMessageStatusNotification.link[0].href;
  const remoteInfos = getRemoteInfosFromImdnResourceUrl(href);
  const imdnStatus = chatMessageStatusNotification.status;

  if (!remoteInfos || !imdnStatus) {
    console.error(
      "Invalid chatMessageStatusNotification",
      chatMessageStatusNotification
    );
    return;
  }

  setConversationMessagePartial(
    remoteInfos.messageId,
    {
      imdns: {
        imdn: [
          {
            imdnInfo: [
              {
                type: imdnStatus,
                date: new Date().toISOString(),
              },
            ],
            originalTo: remoteInfos.phoneNumber,
          },
        ],
      },
    },
    Conversation.getOrCreate({
      phoneNumber: remoteInfos.contributionId || remoteInfos.phoneNumber,
    }).conversation.id
  );
}

export async function updateConversationsInDatabase(conversationIds: string[]) {
  const tx = (await window.getVerseDb()).transaction(
    "conversations",
    "readwrite"
  );

  for (const conversationId of conversationIds) {
    const conversation = conversationsState.conversations.get(conversationId);

    if (!conversation) {
      continue;
    }

    await tx.store.put(
      proxyToRaw(proxyToRaw(conversation.serialize())),
      conversationId
    );
  }

  await tx.done;
}

export async function createThumbnail(file: Blob) {
  const mediaService = MediaServiceProvider();
  const mediaRequest = await generateMediaServiceMediaRequest(file);
  try {
    const mediaResponse = await mediaService.resizeMedia(mediaRequest, {})
      .response;
    console.log("mediaResponse:", mediaResponse);

    if (mediaResponse) {
      if (mediaResponse.source.oneofKind === "data") {
        return new Blob([mediaResponse.source.data], {
          type: mediaResponse.mimeType,
        });
      }
    }
  } catch (error) {
    console.error("There was an error uploading the file:", error);
  }

  // Fallback to main file
  return file;
}

export const fileToBase64 = async (file: Blob | null): Promise<string> => {
  if (!file) {
    return "";
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.onerror = () => {
      reader.abort();
      reject(new Error("Error reading the Blob as base64"));
    };

    reader.readAsDataURL(file);
  });
};

export const base64ToBlob = (base64, contentType) => {
  const bytes = atob(base64.split(",")[1]);
  const byteNumbers = new Array(bytes.length);

  for (let i = 0; i < bytes.length; i++) {
    byteNumbers[i] = bytes.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
};

export async function blobToUint8Array(blob: Blob): Promise<Uint8Array> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result instanceof ArrayBuffer) {
        resolve(new Uint8Array(reader.result));
      }
    };
    reader.onerror = () => {
      reject(new Error("Failed to convert blob to Uint8Array"));
    };
    reader.readAsArrayBuffer(blob);
  });
}

async function generateMediaServiceMediaRequest(file) {
  const mediaBytes = await blobToUint8Array(file);

  const originalMedia = Media$.create({
    source: { oneofKind: "data", data: mediaBytes },
    resolution: {
      width: THUMBNAIL_SIZE,
      height: THUMBNAIL_SIZE,
    },
  });

  const myMedia = Media.create({
    mediaOriginal: originalMedia,
  });

  return ResizeMediaRequest.create({
    media: myMedia,
    type: MediaModel.Media_Type.IMAGE, // your type value here
    resizeConfiguration: Media_ResizeConfiguration.create({
      mode: Media_ResizeConfiguration_Mode.RESIZE,
    }),
  });
}

export function formatFileSizeToHumanReadable(fileSize?: number) {
  if (!fileSize) {
    return "";
  }

  if (fileSize === 0) {
    return 0;
  }

  const kb = 1024;
  const sizes = ["B", "KB", "MB"];
  const i = Math.floor(Math.log(fileSize) / Math.log(kb));

  return parseFloat((fileSize / Math.pow(kb, i)).toFixed(2)) + " " + sizes[i];
}

export const getContactsByImdn = (
  type: ImdnInfo["type"],
  imdns: Imdn[],
  contacts: WebGwContact[]
) => {
  return imdns.flatMap((imdn) =>
    imdn.imdnInfo
      .filter((imdnInfo) => imdnInfo.type === type)
      .map((imdnInfo) => {
        return {
          contact:
            contacts.find(
              (participant) =>
                !!participant.filterContactOnPhone(imdn.originalTo)
            ) || WebGwContact.fromPhoneNumber(imdn.originalTo)!,
          phoneNumber: imdn.originalTo,
          date: imdnInfo.date,
        };
      })
  );
};

export async function failPreviousOngoingMessages() {
  for (const conversation of conversationsState.conversations.values()) {
    const messages = conversation
      .getMessages()
      .filter(
        (message) =>
          message.uploadProgress !== undefined && message.uploadProgress < 100
      );
    let updateDatabase = false;

    messages.forEach((message) => {
      updateDatabase = true;
      console.log("Failing message ", message["imdn.Message-ID"]);

      setConversationMessageAsFailed(
        message["imdn.Message-ID"],
        message.To,
        message.Date,
        false
      );
    });

    if (updateDatabase) {
      await updateConversationInDatabase(conversation.id);
    }
  }
}
