import { proxyToRaw } from "..";
import WebGwContact, { WebGwContactList } from "../helpers/WebGwContact";
import NmsMessage from "../messaging/NmsMessage";
import { getLoadedContacts } from "../messaging/contactsAtoms";
import Call from "./Call";
import { CallMap, callsState } from "./callAtoms";

export function insertCallFromNms(nmsMessage: NmsMessage) {
  const contacts = getLoadedContacts();
  const senderAddress = nmsMessage.getSenderAddress();

  const contact =
    contacts?.findWithNumber(senderAddress) ??
    WebGwContact.fromPhoneNumber(senderAddress);

  Call.create(contact, nmsMessage);
}

export function insertNewCallsIntoRecentCallsMap(
  messages: NmsMessage[],
  contacts: WebGwContactList,
  callMap: CallMap
) {
  const ignoreIdSet = new Set<string>();

  // assume messages are already sorted by date

  for (const message of messages) {
    const phoneNumber = message.Direction?.startsWith("In")
      ? message.From
      : message.To;

    if (!phoneNumber) {
      console.error("Message does not have a From field", message);
      continue;
    }

    const contact =
      contacts.findWithNumber(phoneNumber) ??
      WebGwContact.fromPhoneNumber(phoneNumber);

    const context = message["Message-Context"];
    if (context === "message/callhistory") {
      addRecentCall({
        contact: contact!,
        callMap,
        callId: message["imdn.Message-ID"],
        message,
        ignoreIdSet,
      });
    } else {
      // ignore other Message-Context here
    }
  }
}

function addRecentCall({
  contact,
  callMap,
  callId,
  message,
  ignoreIdSet,
}: {
  contact: WebGwContact;
  callMap: CallMap;
  callId: string;
  message: NmsMessage;
  ignoreIdSet: Set<string>;
}) {
  callMap.set(callId, new Call(contact, message));
  callsState.mapVersion++;
  ignoreIdSet.add(message["imdn.Message-ID"]);
}

export async function updateCallsInDatabase(callIds: string[]) {
  const tx = (await window.getVerseDb()).transaction("calls", "readwrite");

  for (const callId of callIds) {
    const call = callsState.calls.get(callId);

    if (!call) {
      continue;
    }

    await tx.store.put(proxyToRaw(proxyToRaw(call.serialize())), callId);
  }

  await tx.done;
}

export function compareCallId(callIdOne: string, CallIdTwo: string): boolean {
  const callIdPrefixes: string[] = ["un-pns-", "tr-pns-"];

  let callIdOneNoPrefix = callIdOne;
  for (let prefix of callIdPrefixes) {
    callIdOneNoPrefix = callIdOneNoPrefix.replaceAll(prefix, "");
  }
  let callIdTwoNoPrefix = CallIdTwo;
  for (let prefix of callIdPrefixes) {
    callIdTwoNoPrefix = callIdTwoNoPrefix.replaceAll(prefix, "");
  }

  return callIdOneNoPrefix === callIdTwoNoPrefix;
}

export function formatCallIdWithoutPrefixes(CallId: string) {
  const callIdPrefixes: string[] = ["un-pns-", "tr-pns-"];

  let callIdNoPrefix = CallId;
  for (let prefix of callIdPrefixes) {
    callIdNoPrefix = callIdNoPrefix.replaceAll(prefix, "");
  }

  return callIdNoPrefix;
}
