import { queryClient } from "@/utils/queryClient";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { atom } from "jotai";
import {
  fetchChatbotBanners,
  fetchChatbotDirectoryCategorized,
  fetchChatbotInfo,
} from ".";
import type WebGwContact from "../helpers/WebGwContact";
import {
  getConfig,
  useConfig,
  useSuspenseConfig,
} from "../messaging/configAtoms";

export function getLoadedChatbots() {
  return queryClient.getQueryData<
    Awaited<ReturnType<typeof fetchChatbotDirectoryCategorized>>
  >(["chatbotDirectory"]);
}

export async function getChatbotDirectory() {
  const config = await getConfig();
  return queryClient.fetchQuery({
    queryKey: ["chatbotDirectory", config],
    queryFn: () => fetchChatbotDirectoryCategorized(config),
  });
}

export function useChatbotDirectory() {
  const config = useSuspenseConfig();

  return useSuspenseQuery({
    queryKey: ["chatbotDirectory", config],
    queryFn: () => fetchChatbotDirectoryCategorized(config),
  });
}

export function useChatbotBanners() {
  const config = useSuspenseConfig();

  return useSuspenseQuery({
    queryKey: ["chatbotBanners", config],
    queryFn: () => fetchChatbotBanners(config),
  });
}

export const selectedChatbotAtom = atom<WebGwContact | undefined>(undefined);

export function useChatbotInfo(contact?: WebGwContact) {
  const config = useConfig();

  return useQuery({
    queryKey: ["chatbotInfo", contact?.id, config] as const,
    queryFn: async ({ queryKey: [, botIdToFetch, config] }) => {
      if (!botIdToFetch || !config) {
        return null;
      }

      try {
        return await fetchChatbotInfo(botIdToFetch, config);
      } catch (e) {
        console.error("Error when fetching chatbot info", botIdToFetch);
        console.error("Caught", e);
        return null;
      }
    },
    enabled: !!contact?.isChatbot && !!config,
    // We always want fresh info in case bot gets published/unpublished
    refetchOnMount: "always",
  });
}
