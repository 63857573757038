import {
  callActiveAtom,
  incomingCallInfosAtom,
  outgoingCallInfosAtom,
} from "@/components/callOverlays/CallOverlays";
import { paths } from "@/routerPaths";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  CapabilityType,
  checkCapabilityOnServer,
} from "../contacts/useCapabilities";
import {
  GRANTED,
  PROMPT,
  checkMicPermissions,
  checkVideoCallPermissions,
  racePromisesWithTimeout,
  requestAudioCallPermission,
  requestVideoCallPermissions,
} from "../helpers/browserPermissions";
import {
  LocalHardwareStatusValues,
  checkAvailableHardware,
} from "../helpers/checkAvailableDeviceHardware";
import { useToast } from "../helpers/toastManager";
import { checkAvailableHardwareAtom } from "./callAtoms";

export function useCall(): UseCall {
  const { showToast } = useToast();
  const [outgoingCallInfos, setOutgoingCallInfos] = useAtom(
    outgoingCallInfosAtom
  );
  const incomingCallInfos = useAtomValue(incomingCallInfosAtom);
  const callActive = useAtomValue(callActiveAtom);
  const setShowHardwareModal = useSetAtom(checkAvailableHardwareAtom);

  const callWithVideo = async (numberToCall) => {
    const hardwareAvailable = await checkAvailableHardware();
    if (hardwareAvailable !== LocalHardwareStatusValues.AVAILABLE) {
      setShowHardwareModal(true);
    }
    call(
      numberToCall,
      true,
      checkVideoCallPermissions,
      requestVideoCallPermissions
    );
  };

  const callWithAudio = async (numberToCall) => {
    const hardwareAvailable = await checkAvailableHardware();
    if (hardwareAvailable === LocalHardwareStatusValues.NO_MIC) {
      setShowHardwareModal(true);
    }
    call(numberToCall, false, checkMicPermissions, requestAudioCallPermission);
  };

  const canAcceptOrMakeCall = () => {
    return !outgoingCallInfos && !incomingCallInfos && !callActive;
  };

  const call = async (
    numberToCall,
    isVideo,
    checkPermission: (
      checkPromptState?: boolean
    ) => Promise<string | undefined>,
    requestPermission: () => Promise<void>
  ) => {
    if (!canAcceptOrMakeCall()) {
      return;
    }

    const permission = await callPhoneNumberWithPermission(
      numberToCall,
      isVideo,
      checkPermission,
      requestPermission
    );

    //@ts-ignore
    if (permission && (permission !== GRANTED || permission !== PROMPT)) {
      showToast(permission);
    }
  };

  async function callPhoneNumberWithPermission(
    phoneNumber: string,
    isVideo: boolean,
    checkPermission: (
      checkPromptState?: boolean
    ) => Promise<string | undefined>,
    requestPermission: () => Promise<void>
  ) {
    if (!canAcceptOrMakeCall()) {
      return;
    }

    const permission = await checkPermission();

    if (permission === GRANTED) {
      callPhoneNumber(phoneNumber, isVideo);
    } else if (permission === PROMPT) {
      await requestPermission();
      try {
        const [updatedPermission] = await racePromisesWithTimeout(
          [checkPermission(false)],
          10000
        );

        if (updatedPermission === GRANTED) {
          callPhoneNumber(phoneNumber, isVideo);
        } else {
          return updatedPermission;
        }
      } catch (error) {
        console.error("Error checking permission:", error);
      }
    } else {
      return permission;
    }
  }

  function callPhoneNumber(phoneNumber: string, isVideo: boolean) {
    console.log(
      `callPhoneNumber: phoneNumber ${phoneNumber}, isVideo=${isVideo}`
    );

    // A call could be initialized with unknown numbers. Check caps here for the feedback on end call and also for next calling with same number (cache will be available before making the call).
    checkCapabilityOnServer(
      phoneNumber,
      isVideo ? CapabilityType.VIDEO : CapabilityType.VOICE
    );

    // Fullscreen everywhere except on Odience stream page
    const startFullScreen: boolean = !location.href.includes(
      paths.details.substring(0, paths.details.indexOf(":"))
    );

    setOutgoingCallInfos({
      number: phoneNumber,
      isVideo,
      startFullScreen,
    });
  }

  return {
    callWithAudio,
    callWithVideo,
    canAcceptOrMakeCall: canAcceptOrMakeCall(),
  };
}

type UseCall = {
  callWithAudio: (number: string) => void;
  callWithVideo: (number: string) => void;
  canAcceptOrMakeCall: boolean;
};
