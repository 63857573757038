import { atom, getDefaultStore } from "jotai";
import type WebGwContact from "../helpers/WebGwContact";
import { WebGwContactList } from "../helpers/WebGwContact";
import {
  getContactsAsync,
  getLoadedContacts,
} from "../messaging/contactsAtoms";

export type Filters = "all" | "verse";
const _contactFilter = atom<Filters>("all");
export const contactFilter = atom(
  (get) => get(_contactFilter),
  async (_get, set, filter: Filters) => {
    const contacts = await getContactsAsync();
    const verseContacts = filterContactWithCaps(contacts);

    switch (filter) {
      case "verse":
        set(_contactFilter, filter);
        set(filteredContactsAtom, verseContacts);
        break;
      case "all":
      default:
        set(_contactFilter, filter);
        set(filteredContactsAtom, contacts);
    }
  }
);
export const filteredContactsAtom = atom<WebGwContactList | null | undefined>(
  undefined
);
const defaultStore = getDefaultStore();
filteredContactsAtom.onMount = (set) => {
  const contacts = getLoadedContacts();
  if (contacts) {
    set(contacts);
  } else {
    defaultStore.set(contactFilter, "all");
  }
};

function filterContactWithCaps(contactList?: WebGwContactList | null) {
  if (!contactList) {
    return new WebGwContactList();
  }

  return contactList.filter((contact) => contact.isVerse) as WebGwContactList;
}

export const numberOfFilteredContactsAtom = atom(1);

export const displayContactAtom = atom<WebGwContact | null>(null);
