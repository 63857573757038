import { atom } from "jotai";
import { proxy, useSnapshot } from "valtio";
import { derive, devtools, proxyMap } from "valtio/utils";
import type Call from "./Call";

declare module "valtio" {
  function useSnapshot<T extends object>(p: T): T;
}

export type CallId = string;
export type CallMap = Map<CallId, Call>;

const lastSelectedCallIdKey = "lastSelectedCallId";

let _selectedCallId = localStorage.getItem(lastSelectedCallIdKey) || null;
export const callsState = proxy({
  get selectedCallId() {
    if (_selectedCallId && !this.calls.has(_selectedCallId)) {
      return null;
    }
    return _selectedCallId;
  },
  set selectedCallId(val: string | null) {
    _selectedCallId = val;
    if (val == null) {
      localStorage.removeItem(lastSelectedCallIdKey);
    } else {
      localStorage.setItem(lastSelectedCallIdKey, val);
    }
  },
  calls: proxyMap(new Map()) as CallMap,
  mapVersion: 0,
});

if (import.meta.env.DEV && "__REDUX_DEVTOOLS_EXTENSION__" in window) {
  const debugCallState = derive({
    ...Object.assign(
      // @ts-ignore
      ...Object.keys(callsState).map((key) => ({
        // @ts-ignore
        [key]: (get) => get(callsState)[key],
      }))
    ),
    calls: (get) => Object.fromEntries(get(callsState).calls),
  });

  devtools(debugCallState);
}

export function setSelectedCallId(callId: string | null) {
  if (callsState.selectedCallId === callId) return;

  // before a call is changed, remove the currently selected call if it doesn't have any messages
  if (callsState.selectedCallId) {
    callsState.calls.delete(callsState.selectedCallId);
  }

  callsState.selectedCallId = callId;
}

/**
 * The call returned is editable (ex: ``call.pushMessage(...)``)
 */
export function getSelectedCall(state = callsState): Call | undefined {
  if (state.selectedCallId) {
    return state.calls.get(state.selectedCallId);
  }
}

/**
 * The call returned is not editable and it will update with changes
 */
export function useSelectedCall() {
  return getSelectedCall(useSnapshot(callsState));
}

export function setCalls(newCalls: typeof callsState.calls) {
  // callsState.calls = proxyMap(new Map(newCalls));
  newCalls.forEach((c, id) => {
    // ? not sure why cloning the messages is necessary to reflect an update
    callsState.calls.set(id, c);
  });
  callsState.mapVersion++;
}

export const selectedCallLogsAtom = atom<string[]>([]);
export const selectedCallLogsCheckboxesAtom = atom<string[]>([]);
export const checkAvailableHardwareAtom = atom(false);
