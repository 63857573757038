import { ref } from "valtio";
import {
  FilterType,
  FilterTypeFields,
  chatbotToPhoneNumber,
  filterItem,
  getBotNameFromId,
  isChatbot,
} from "..";
import { ChatbotInfo } from "../chatbots";
import { getLoadedChatbots } from "../chatbots/chatbotAtoms";
import { getLoadedContacts } from "../messaging/contactsAtoms";
import { cleanPhoneNumber } from "../messaging/conversation/conversationUtils/phoneNumberUtils";
import { formatPhoneNumber } from "./formatPhoneNumber";
import {
  CapabilityFetchResult,
  checkPhoneNumberCapsContactList,
  fetchCaps,
} from "./loginAndCaps/";
import { ServiceCapabilityArray } from "./notificationChannel";

const contactAttributeNamesArrayTyped = [
  "address",
  "address; home",
  "address; work",
  "email; home",
  "email; work",
  "email",
  "phone; mobile",
  "phone; home",
  "phone; work",
  "phone",
  "person; Mother",
  "person; Father",
  "person; Sibling",
  "person; Family",
  "person; Friend",
  "person; Other",
  "person",
] as const;

type ContactAttributeArrayTyped = {
  name: (typeof contactAttributeNamesArrayTyped)[number];
  value: string[];
};

type ContactAttributeStringTyped = {
  name: "name" | "company" | "url" | "birthday" | "note" | "photo";
  value: string;
};

type ContactAttribute =
  | ContactAttributeArrayTyped
  | ContactAttributeStringTyped;

type ContactFromRes = {
  attributeList: {
    attribute: ContactAttribute[];
  };
  contactId: string;
  sourceURI: string;
};

export type ContactsResponse = {
  contactCollection: {
    contact: ContactFromRes[];
  };
};

function attrHasArrayValue(
  attr: ContactAttribute
): attr is ContactAttributeArrayTyped {
  return contactAttributeNamesArrayTyped.includes(attr.name as any);
}

type ContactAttributeIndexable = Partial<{
  [K in ContactAttribute["name"]]:
    | (K extends ContactAttributeArrayTyped["name"]
        ? Array<ContactAttributeArrayTyped["value"]> // ! there could be multiple values, so they are mapped to an array.
        : ContactAttributeStringTyped["value"])
    | undefined;
}>;

export type NumberWithType = [
  phoneNumber: string,
  type: string,
  caps: ServiceCapabilityArray,
];

export default interface WebGwContact extends ContactAttributeIndexable {
  [x: string]: unknown;
}
export default class WebGwContact {
  public id: string;
  public initials?: string;
  public isChatbot = false;
  public isVerse = false;
  public caps!: ServiceCapabilityArray;

  // This will be used to save the input provided by the user when looking for a contact
  public userInputNumber?: string;

  private cache!: ReturnType<typeof this.initCache>;

  constructor(contact: ContactFromRes) {
    this.construct();
    this.id = contact.contactId;

    for (const attr of contact.attributeList.attribute) {
      if (attrHasArrayValue(attr)) {
        this[attr.name] ??= [];

        if (attr.name.startsWith("phone")) {
          if (Array.isArray(attr.value)) {
            attr.value[0] = formatPhoneNumber(attr.value[0], "E123");
          } else {
            // ! supporting the old webgw format
            // @ts-expect-error
            attr.value = formatPhoneNumber(attr.value, "E123");
          }
        }

        this[attr.name]!.push(attr.value);
      } else {
        this[attr.name] = attr.value;
      }
    }

    if (this.name) {
      const names = this.name.trim().split(" ");
      if (names.length === 1) {
        this.initials = names[0].substring(0, 1);
      } else if (names.length > 1) {
        this.initials = names[0][0] + names[names.length - 1][0];
      }
    }
  }

  private construct() {
    this.isChatbot = this["phone"] ? isChatbot(this["phone"][0][0]) : false;
    this.isVerse = false;
    this.caps = [];
    this.initCache();
  }

  private initCache() {
    const cache = ref({
      allPhoneNumbers: null as string[] | null,
      allPhoneNumbersWithTypes: null as NumberWithType[] | null,
      refreshCapsPromise: undefined as
        | Promise<CapabilityFetchResult | undefined>
        | undefined,
    });
    this.cache = cache;
    return cache;
  }

  public async refreshCaps() {
    if (this.cache.refreshCapsPromise) return;
    const phoneNumber = this.getMainPhoneNumber();
    if (!phoneNumber) {
      console.error("undefined phone number. Cannot refresh caps");
      return;
    }
    console.log(`[${phoneNumber}]: Refreshing caps`);
    try {
      this.cache.refreshCapsPromise = fetchCaps(phoneNumber, true);

      if (this.cache.refreshCapsPromise) {
        const res = await this.cache.refreshCapsPromise;
        if (res) {
          this.isVerse ||=
            res.caps?.contactServiceCapabilities.userType === "rcs";
          this.caps =
            res.caps?.contactServiceCapabilities.serviceCapability || [];
        }
      }
    } catch (e) {
      console.warn(e, this);
    } finally {
      this.cache.refreshCapsPromise = undefined;
    }
  }

  public filterContact(
    query: string,
    ...filterTypeFields: FilterTypeFields[]
  ):
    | readonly [
        WebGwContact,
        {
          nameIndex?: number | undefined;
          "email; homeIndex"?: [number, number] | undefined;
          "email; workIndex"?: [number, number] | undefined;
          "phone; mobileIndex"?: [number, number] | undefined;
          "phone; homeIndex"?: [number, number] | undefined;
          "phone; workIndex"?: [number, number] | undefined;
          phoneIndex?: number | undefined;
        },
      ]
    | undefined {
    // ? interesting way typescript works with `this`.
    // ? Attributes like "phone; home" which are arrays wouldn't be able to have their returned indices inferred as [number, number]
    const contact = this as WebGwContact;
    const filterOnAll =
      filterTypeFields.length === 0 ||
      filterTypeFields.includes(FilterTypeFields.ALL);
    const fields: string[] = [];

    if (filterOnAll || filterTypeFields.includes(FilterTypeFields.NAME)) {
      fields.push("name");
    }

    if (filterOnAll || filterTypeFields.includes(FilterTypeFields.EMAIL)) {
      fields.push("email; home", "email; work");
    }

    if (
      filterOnAll ||
      filterTypeFields.includes(FilterTypeFields.PHONE_NUMBER)
    ) {
      fields.push("phone", "phone; home", "phone; work", "phone; mobile");
    }

    return filterItem(
      contact,
      query.toLocaleLowerCase(),
      FilterType.PARTIAL,
      ...fields
    );
  }

  public filterContactOnPhone(
    query: string,
    filterType: FilterType = FilterType.PARTIAL
  ) {
    if (!query) {
      return undefined;
    }

    const contact = this as WebGwContact;
    return filterItem(
      contact,
      cleanPhoneNumber(query),
      filterType,
      "phone",
      "phone; home",
      "phone; work",
      "phone; mobile"
    );
  }

  public getMainPhoneNumberType() {
    if (this["phone; mobile"]) {
      return "mobile";
    }
    if (this["phone; work"]) {
      return "work";
    }
    if (this["phone; home"]) {
      return "home";
    }
  }

  //TODO MAKE GET MAIN PHONENUMBER SAME and  GET MAIN PHONENUMBER WITH CAPS same function and return number with caps
  // IF A CONTACT HAS A NUMBER WITH CAPS for now now libphonenumber is complaining so made seperate until we have time to come back
  public getMainPhoneNumber() {
    return this.getAllPhoneNumbers()[0];
  }

  public getMainPhoneNumberWithCaps() {
    return this.getAllPhoneNumbersWithCaps()[0];
  }

  public getAllPhoneNumbers() {
    if (this.cache.allPhoneNumbers && this.cache.allPhoneNumbers.length > 0)
      return this.cache.allPhoneNumbers;

    const phoneNumbers: string[] = [];
    for (const phoneType of [
      "phone",
      "phone; mobile",
      "phone; work",
      "phone; home",
    ]) {
      const phoneVal = this[phoneType] as typeof this.phone;
      if (phoneVal) {
        phoneNumbers.push(...phoneVal.map(([phoneNumber]) => phoneNumber));
      }
    }

    return (this.cache.allPhoneNumbers = phoneNumbers);
  }

  public getAllPhoneNumbersWithCaps() {
    //TODO was never running function properly, need to check caching
    // if (this.cache.allPhoneNumbers) return this.cache.allPhoneNumbers;

    const phoneNumbers: string[] = [];
    for (const phoneType of [
      "phone",
      "phone; mobile",
      "phone; work",
      "phone; home",
    ]) {
      const phoneVal = this[phoneType] as typeof this.phone;
      if (phoneVal) {
        for (const phoneNumber of phoneVal) {
          if (phoneNumber[2]) {
            phoneNumbers.push(phoneNumber[0]);
          }
        }
      }
    }

    return (this.cache.allPhoneNumbers = phoneNumbers as NonNullable<
      (typeof phoneNumbers)[number]
    >[]);
  }

  public async getAllPhoneNumbersWithTypesAndCaps() {
    if (this.cache.allPhoneNumbersWithTypes) {
      return this.cache.allPhoneNumbersWithTypes;
    }

    const phoneNumbers: NumberWithType[] = [];
    const phoneTypes: [phoneKey: string, label: string][] = [
      ["phone", ""],
      ["phone; mobile", "mobile"],
      ["phone; work", "work"],
      ["phone; home", "home"],
    ];

    for (const [phoneKey, label] of phoneTypes) {
      const phoneVal = this[phoneKey] as typeof this.phone;
      if (phoneVal) {
        for (const [phoneNumber] of phoneVal) {
          const res = await checkPhoneNumberCapsContactList(phoneNumber);
          try {
            // TODO - better to have this but check why readonly at the time of the update
            this.isVerse ||= res.isRcs;
          } catch (e) {
            console.error(e);
          }

          if (res.isRcs) {
            phoneNumbers.push([phoneNumber, label, res.caps || []]);
          }
        }
      }
    }

    this.cache.allPhoneNumbersWithTypes = phoneNumbers;
    setTimeout(() => {
      this.cache.allPhoneNumbersWithTypes = null;
    });

    return phoneNumbers;
  }

  public getAllPhoneNumbersWithTypes() {
    if (this.cache.allPhoneNumbersWithTypes) {
      return this.cache.allPhoneNumbersWithTypes;
    }

    const phoneNumbers: NumberWithType[] = [];
    const phoneTypes: [phoneKey: string, label: string][] = [
      ["phone", ""],
      ["phone; mobile", "mobile"],
      ["phone; work", "work"],
      ["phone; home", "home"],
    ];

    for (const [phoneKey, label] of phoneTypes) {
      const phoneVal = this[phoneKey] as typeof this.phone;
      if (phoneVal) {
        phoneNumbers.push(
          ...phoneVal.map(
            ([phoneNumber]: string[]) =>
              [phoneNumber, label, []] as [
                string,
                string,
                ServiceCapabilityArray,
              ]
          )
        );
      }
    }

    // cache for the current call stack
    // this method was being called many times in one component, so it can be cached
    this.cache.allPhoneNumbersWithTypes = phoneNumbers;
    setTimeout(() => {
      this.cache.allPhoneNumbersWithTypes = null;
    });

    return phoneNumbers;
  }

  // use to detect if there are multiple phone numbers
  // TODO if there are, then show a pop up to select which one to use when messaging
  public hasMultiplePhoneNumbers() {
    let hasOne = false;

    for (const [contactKey, contactVal] of Object.entries(this)) {
      if (!contactKey.startsWith("phone")) continue;

      if (contactVal) {
        if (hasOne) {
          return true;
        }
        hasOne = true;
      }
    }

    return false;
  }

  // The logic here is that if we ever were RCS, we will make voice/video/messaging possible since those SIP messages
  // will go through to the IMS anyway.

  public hasVoiceCap() {
    return this.caps.some((cap) => cap.capabilityId === "IPVoiceCall");
  }

  public hasMessagingCap() {
    return this.caps.some((cap) => cap.capabilityId === "Chat");
  }

  public hasVideoCap() {
    return this.caps.some((cap) => cap.capabilityId === "IPVideoCall");
  }

  public static fromAttributes(
    obj: ContactAttributeIndexable & {
      id: WebGwContact["id"];
      initials?: WebGwContact["initials"];
    }
  ) {
    const contact = WebGwContact.from(obj);

    if (!contact.initials) {
      const names = contact.name?.trim().split(" ");
      if (names && names.length === 1) {
        contact.initials = names[0].substring(0, 2);
      } else if (names && names.length > 1) {
        contact.initials = names[0][0] + names[names.length - 1][0];
      }
    }

    return contact;
  }

  public static fromPhoneNumber(
    phoneNumber: string,
    matchKnownContacts: boolean = false
  ) {
    if (!phoneNumber) return undefined;

    let contact: WebGwContact | undefined = undefined;

    if (matchKnownContacts) {
      if (isChatbot(phoneNumber)) {
        const [chatbots] = getLoadedChatbots() ?? [];

        const chatbotInfo = chatbots?.find((chatbot) =>
          chatbot.id.includes(phoneNumber)
        );
        if (chatbotInfo) {
          contact = WebGwContact.fromChatbotInfo(chatbotInfo);
        }
      } else {
        contact = getLoadedContacts()?.findWithNumber(phoneNumber);
      }
    }

    if (!contact) {
      contact = WebGwContact.fromAttributes({
        id: phoneNumber,
        phone: [[phoneNumber, ""]],
      });

      contact.userInputNumber = formatPhoneNumber(phoneNumber, "E164");
    }

    return contact;
  }

  public static fromChatbotInfo(
    bot: Pick<
      ChatbotInfo,
      "bot_id" | "display_name" | "action_image_url" | "id"
    > &
      Partial<Pick<ChatbotInfo, "name" | "icon">>
  ) {
    // not actually a phone number, but it's what is used to communicate
    // remove the `<` and `>` from the bot id

    const chatbotPhoneNumber = chatbotToPhoneNumber(bot.bot_id || bot.id);

    const contact = WebGwContact.fromAttributes({
      id: chatbotPhoneNumber[0],
      phone: [chatbotPhoneNumber],
      name: bot.name || bot.display_name,
      photo: bot.icon || bot.action_image_url,
    });

    contact.isChatbot = true;

    return contact;
  }

  public static from(obj: object) {
    const contact = Object.setPrototypeOf(
      obj,
      WebGwContact.prototype
    ) as WebGwContact;
    contact.construct();
    return contact;
  }

  public noNameReturnPhoneNumber(
    phoneNumber?: string,
    includeFullSip: boolean = false
  ) {
    const name = this.name?.trim();

    if ((phoneNumber || this.getMainPhoneNumber()) && !name) {
      if (this.isChatbot) {
        return getBotNameFromId(this.id, includeFullSip);
      } else {
        // Fallback to number if format did not work
        return formatPhoneNumber(
          phoneNumber || this.getMainPhoneNumber(),
          "E123"
        );
      }
    }
    return name;
  }
}

export class WebGwContactList extends Array<WebGwContact> {
  constructor(contactRes?: ContactsResponse, sort = true) {
    super();
    // using the .map method on this seems to call the constructor. This is here to avoid nothing being passed to the constructor
    if (!contactRes?.contactCollection?.contact) return;
    for (const c of contactRes.contactCollection.contact) {
      if (c) this.push(new WebGwContact(c));
    }
    if (sort) {
      this.sortContacts();
    }
  }

  public static fromArray(contacts: WebGwContact[]) {
    return Object.setPrototypeOf(
      contacts,
      WebGwContactList.prototype
    ) as WebGwContactList;
  }

  public findWithNumber(number: string) {
    const contact = this.findLast((contact) =>
      contact.filterContactOnPhone(
        cleanPhoneNumber(number),
        isChatbot(number) ? FilterType.PARTIAL : FilterType.PHONE_NUMBER
      )
    );

    if (contact) {
      contact.userInputNumber = formatPhoneNumber(number, "E164");
    }

    return contact;
  }

  sortContacts() {
    return this.sort((a, b) => {
      const aValue = a.name;
      const bValue = b.name;
      if (!aValue || !bValue) {
        return 0;
      }
      return aValue.localeCompare(bValue);
    });
  }

  filterContacts(query: string) {
    const filteredContacts: Array<
      NonNullable<
        ReturnType<(typeof WebGwContact)["prototype"]["filterContact"]>
      >
    > = [];
    for (const contact of this) {
      const filteredContact = contact.filterContact(query);
      if (filteredContact) {
        filteredContacts.push(filteredContact);
      }
    }
    return filteredContacts;
  }

  public static filterContacts(query: string, contacts: Array<WebGwContact>) {
    const filteredContacts: Array<
      NonNullable<
        ReturnType<(typeof WebGwContact)["prototype"]["filterContact"]>
      >
    > = [];
    for (const contact of contacts) {
      const filteredContact = contact.filterContact(query);
      if (filteredContact) {
        filteredContacts.push(filteredContact);
      }
    }
    return filteredContacts;
  }
}
// The contactsQuery will lose the WebGwContactList type and just return an array of WebGwContact every time we update the atoms,
// hence causing failure everywhere where we expect WebGwContactList

export function wrapToWebGwContactList(
  webGwContacts: WebGwContactList | WebGwContact[] | null | undefined
) {
  if (!webGwContacts) {
    return webGwContacts;
  }

  if (!(webGwContacts instanceof WebGwContactList)) {
    return WebGwContactList.fromArray(webGwContacts);
  }

  return webGwContacts;
}
