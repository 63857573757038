import { cleanPhoneNumber } from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import { queryClient } from "@/utils/queryClient";
import { getDefaultStore } from "jotai";
import {
  baseGeoIpUrl,
  baseWebGwUrl,
  isChatbot,
  isValidPhoneNumber,
} from "../..";
import {
  getBrowserName,
  getBrowserVendor,
  getBrowserVersion,
} from "../../../utils/helpers/getBrowser";
import { capabilitiesAtom } from "../../contacts/capabilitiesAtoms";
import { getConfig } from "../../messaging/configAtoms";
import { fetchWithTimeout } from "../Utils";
import { formatPhoneNumber } from "../formatPhoneNumber";
import {
  getImpi,
  getLocalAccessToken,
  getLocalUser,
  getPassword,
  getSipUser,
  getUsername,
} from "../localstorage";
import {
  CapabilityNotification,
  ServiceCapabilityArray,
} from "../notificationChannel";
import {
  LOGIN_RESULT_NOTIFICATION_STATUS_CODE,
  LoginResultNotification,
  WebGwResponse,
} from "../provisionRequest";
import { generateDeviceId } from "./publishCaps";
export const domain = window?._env_?.DOMAIN;
export const PROV_WAIT_TIMEOUT_MS = 30000;

export type DeviceIdentityData = {
  uri: string;
  authuser: string;
  terminal_model: string;
  client_vendor: string;
  client_version: string;
  terminal_sw_version: string;
  terminal_vendor: string;
  friendly_device_name: string;
  device_id: string;
  impi: string | null;
  force: boolean | null;
};

export async function register(
  user: string,
  deviceName: string,
  force: boolean = false
) {
  const browserName = getBrowserName(navigator.userAgent);
  const browserVendor = getBrowserVendor(navigator.userAgent);
  const browserVersion = getBrowserVersion(navigator.userAgent);
  const impi = getImpi();
  const deviceId = generateDeviceId(user, deviceName);
  console.log("DeviceId:", deviceId);

  // const provUrl: string = "/client/v1/register";
  const provPath: string = `/client/v1/odience?msisdn=${encodeURIComponent(
    user
  )}`;
  const registerRes = await fetchWithTimeout(new URL(provPath, baseWebGwUrl), {
    method: "POST",
    body: JSON.stringify({
      uri: `sip:${user}@${domain}`,
      authuser: `${user}@${domain}`,
      terminal_model: browserName,
      client_vendor: "STVE",
      client_version: "web-1.0",
      terminal_sw_version: browserVersion,
      terminal_vendor: browserVendor,
      friendly_device_name: deviceName,
      device_id: deviceId,
      impi: impi,
      force: force,
    } satisfies DeviceIdentityData),
  });
  return registerRes;
}

export async function provWait(accessToken: string) {
  console.info("Sending /provwait request while waiting for OTP");
  const provWaitRes = await fetchWithTimeout(
    new URL(
      `/client/v1/provwait?access_token=${accessToken}&_t=${Date.now()}&resFormat=json`,
      baseWebGwUrl
    ),
    {
      method: "POST",
      referrerPolicy: "no-referrer-when-downgrade",
      cache: "no-store",
    },

    PROV_WAIT_TIMEOUT_MS
  ).catch((e) => {
    // Timeout, create a fake otp expired response

    if (e.name === "AbortError") {
      const loginResultNotification: LoginResultNotification = {
        reason: "OTP not provided in time",
        status_code: LOGIN_RESULT_NOTIFICATION_STATUS_CODE.OtpNotProvided,
        access_token: "",
        state: "",
        result: "",
      };

      const webGwResponse: WebGwResponse = {
        notificationList: {
          loginResultNotification: loginResultNotification,
        },
      };

      const response = new Response(JSON.stringify(webGwResponse), {
        status: 408,
        headers: { "Content-Type": "application/json" },
      });

      return Promise.resolve(response);
    } else {
      return e;
    }
  });
  return provWaitRes;
}
//Used after Otp so that there is no timeout on the Device Management provisioning step
export async function provWaitAfterOtp(accessToken: string, signal) {
  console.info("Sending /provwait request after correct OTP");
  const provWaitRes = fetch(
    new URL(
      `/client/v1/provwait?access_token=${accessToken}&_t=${Date.now()}&resFormat=json`,
      baseWebGwUrl
    ),
    {
      method: "POST",
      referrerPolicy: "no-referrer-when-downgrade",
      cache: "no-store",
      signal: signal,
    }
  ).catch((e) => {
    return e;
  });
  return provWaitRes;
}

export async function abortProv(accessToken: string) {
  console.info("Sending /abortprov request");
  const provAbortRes = fetch(
    new URL(`/client/v1/abortprov?access_token=${accessToken}`, baseWebGwUrl),
    {
      method: "POST",
      referrerPolicy: "no-referrer-when-downgrade",
      cache: "no-store",
    }
  ).catch((e) => {
    return e;
  });
  return provAbortRes;
}

export async function otpRequest(otp: string, accessToken: string) {
  console.debug(`[${accessToken}]: Fetched OTP, sending OTP '${otp}'`);
  const res = await fetchWithTimeout(
    new URL(
      `/anonymous/otp?access_token=${accessToken}&_t=${Date.now()}&resFormat=json`,
      baseWebGwUrl
    ),
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      credentials: "include",
      body: JSON.stringify({
        OTP: otp,
      }),
      cache: "no-store",
    }
  );
  if (res?.ok) {
    console.debug("Correctly Sent OTP", res);
    localStorage.setItem("OTP", accessToken); // TODO OTP shouldn't need to be set in local storage
  } else {
    console.warn("Failed to send OTP, res:", res);
  }
}

export async function login(accessToken: string) {
  const loginRes = await fetchWithTimeout(
    new URL(
      `/client/v1/login?access_token=${accessToken}&_t=${Date.now()}&resFormat=json`,
      baseWebGwUrl
    ),
    {
      method: "POST",
      referrerPolicy: "no-referrer-when-downgrade",
      cache: "no-store",
    }
  );
  return loginRes;
}

export async function logout(accessToken: string) {
  const logoutRes = await fetchWithTimeout(
    new URL(
      `/client/v1/logout?access_token=${accessToken}&_t=${Date.now()}&resFormat=json`,
      baseWebGwUrl
    ),
    {
      method: "POST",
      referrerPolicy: "no-referrer-when-downgrade",
    }
  );
  return logoutRes;
}

export async function relogin() {
  const sipUser = getSipUser();
  const username = getUsername();
  const password = getPassword();
  if (
    sipUser &&
    username &&
    password &&
    sipUser.length > 0 &&
    username.length > 0 &&
    password.length > 0
  ) {
    const loginRes = await fetchWithTimeout(
      new URL(
        `/client/v1/relogin?_t=${Date.now()}&resFormat=json`,
        baseWebGwUrl
      ),
      {
        method: "POST",
        referrerPolicy: "no-referrer-when-downgrade",
        headers: { "Content-Type": "application/json" },
        mode: "cors",
        credentials: "include",
        cache: "no-store",
        body: JSON.stringify({
          uri: sipUser,
          username: username,
          password: password,
        }),
      }
    );
    return loginRes;
  }
}

export type Config = {
  "application/3gpp_ims/ext/gsma/username": string;
  "application/3gpp_ims/ext/gsma/userpwd": string;
  "application/3gpp_ims/home_network_domain_name": string;
  "application/3gpp_ims/private_user_identity": string;
  "application/other/director/otp": string;
  "application/messaging/chatbot/bannerboturl": string;
  "application/messaging/chatbot/botinfofqdnroot": string;
  "application/messaging/chatbot/chatbotdirectory": string;
  "application/messaging/chatbot/criticalboturl": string;
  "application/messaging/filetransfer/ ftmax1tomanyrecipients": string;
  "application/messaging/filetransfer/ftautaccept": string;
  "application/messaging/filetransfer/fthttpcspwd": string;
  "application/messaging/filetransfer/fthttpcsuri": string;
  "application/messaging/filetransfer/fthttpcsuser": string;
  "application/messaging/filetransfer/fthttpdluri": string;
  "application/messaging/filetransfer/fthttpfallback": string;
  "application/messaging/filetransfer/ftwarnsize": string;
  "application/messaging/filetransfer/maxsizefiletr": string;
  "application/messaging/filetransfer/maxsizefiletrincoming": string;
  "application/services/chatauth": string;
  "application/services/composerauth": string;
  "application/services/ftauth": string;
  "application/services/geolocpullauth": string;
  "application/services/geolocpushauth": string;
  "application/services/groupchatauth": string;
  "application/services/ir51videoauth": string;
  "application/services/ir51voiceauth": string;
  "application/services/isauth": string;
  "application/services/postcallauth": string;
  "application/services/presenceprfl": string;
  "application/services/rcsipvideocallauth": string;
  "application/services/rcsipvoicecallauth": string;
  "application/services/sharedmapauth": string;
  "application/services/sharedsketchauth": string;
  "application/services/standalonemsgauth": string;
  "application/services/supportedrcsprofileversions": string;
  "application/services/supportedrcsversions": string;
  "application/services/vsauth": string;
};

type UserLocationCountryRes = {
  phone_number: UserLocationCountry;
};

export type UserLocationCountry = {
  country_iso_code: string;
  country_code: string;
};

export async function fetchAndStoreUserLocationCountryCode() {
  const response = await fetchWithTimeout(
    new URL("api/v1/geoip/country", baseGeoIpUrl),
    { method: "GET" }
  );
  const location = (await response?.json()) as UserLocationCountryRes;

  console.log("User location country fetched ", location);

  return location.phone_number;
}

const capsWarnings = async (remoteNumber: string) => {
  const user = getLocalUser();
  if (!user) {
    console.error("No local user");
    return false;
  }

  const accessToken = getLocalAccessToken();
  if (!accessToken) {
    console.error("No local access token");
    return false;
  }

  const config = await getConfig();
  if (!config) {
    console.error("Null config");
    return false;
  }

  return true;
};

const parsedCapsObj = async (
  number: string,
  force = false
): Promise<CapabilityFetchResult> => {
  number = formatPhoneNumber(number, "E164");

  if (isChatbot(number)) {
    return {
      status: CapabilityServerStatus.FROM_CACHE,
      caps: {
        contactServiceCapabilities: {
          resourceURL: "",
          serviceCapability: [{ capabilityId: "Chat", status: "Enabled" }],
          uri: "",
          userType: "rcs",
        },
      },
    };
  }

  // Queries can only be made on international numbers
  if (!isValidPhoneNumber(number) || !number.startsWith("+")) {
    console.log("Non valid phone number ", number, " for caps query");

    // We update the caps empty to trigger the re-render of the ui that uses the atom for caps
    updateLocalCacheCapabilities(number);

    return {
      status: CapabilityServerStatus.FROM_CACHE,
      caps: {
        contactServiceCapabilities: {
          resourceURL: "",
          uri: "",
        },
      },
    };
  }

  const queryKey = ["caps", number];
  const res = await queryClient.fetchQuery({
    // 5 min cache time
    staleTime: 1000 * 60 * 5,
    queryKey: queryKey,
    queryFn: async () => {
      console.log("Fetching capabilities for ", number);

      // Cache will be handled by the server now
      const user = getLocalUser()!;
      const accessToken = getLocalAccessToken()!;

      const res = await fetchWithTimeout(
        new URL(
          `/capabilitydiscovery/v1/${user}/contactCapabilities/${await formatPhoneNumber(
            number,
            "SIP"
          )}?access_token=${accessToken}&_t=${Date.now()}&resFormat=json&force=${force}`,
          baseWebGwUrl
        ),
        {
          method: "GET",
          referrerPolicy: "no-referrer-when-downgrade",
          mode: "cors",
          credentials: "include",
          cache: "no-store",
        }
      );

      if (res) {
        let caps;
        try {
          caps = (await res.json()) as CapabilityNotification;
        } catch (err) {}

        return { status: res.status, caps };
      }

      return { status: CapabilityServerStatus.UNKNOWN };
    },
  });

  console.log("Capabilities for ", number, ": ", res);

  /**
   * The server will only refresh the caps if passing the force flag.
   * 202 case is for unknown number or caps expired (no caps returned in the end)
   * 200 is for known capabilities
   */
  const caps = {
    status:
      res.status === 202
        ? CapabilityServerStatus.UNKNOWN
        : CapabilityServerStatus.FROM_CACHE,
    caps: res.caps,
  };

  // Caps are currently known on the server, we update the local cache right away, an update may come on the notification channel later (only server knows the rules to refresh)
  if (caps.status === CapabilityServerStatus.FROM_CACHE) {
    updateLocalCacheCapabilities(
      number,
      caps.caps?.contactServiceCapabilities.serviceCapability
    );
  }

  return caps;
};

export type CapabilityFetchResult = {
  status: CapabilityServerStatus;
  caps?: CapabilityNotification;
};
export enum CapabilityServerStatus {
  FROM_CACHE,
  UNKNOWN,
}

export const invalidateCapsCache = async (number) => {
  const queryKey = ["caps", formatPhoneNumber(number, "E164")];

  await queryClient.invalidateQueries({ queryKey });
};

export async function fetchCaps(remoteNumber: string, force: boolean = false) {
  if (!capsWarnings(remoteNumber)) return;

  return parsedCapsObj(remoteNumber, force);
}

export const getMyCapabilities = (
  key: string | number,
  configs: { config: any }
) => {
  const serviceConfig = configs.config;
  const serviceKey = "application/services/" + key;
  return serviceConfig[serviceKey];
};

export const checkPhoneNumberCapsContactList = async (number: string) => {
  return _checkPhoneNumberCaps(number, false);
};

export const checkPhoneNumberCaps = async (number: string) => {
  return _checkPhoneNumberCaps(number, true);
};

const _checkPhoneNumberCaps = async (number: string, force: boolean) => {
  try {
    const parsedObj = await parsedCapsObj(number, force);

    return {
      status: parsedObj.status,
      isRcs: parsedObj.caps?.contactServiceCapabilities.userType === "rcs",
      caps: parsedObj.caps?.contactServiceCapabilities.serviceCapability,
    };
  } catch (e) {
    console.error("Error checking phone number capabilities ", e);
    return { status: CapabilityServerStatus.UNKNOWN, isRcs: false, caps: [] };
  }
};

const defaultStore = getDefaultStore();

export async function updateLocalCacheCapabilities(
  number: string,
  serviceCapabilityArray?: ServiceCapabilityArray
) {
  console.log(
    "Updating local cache capabilities for ",
    number,
    ": ",
    serviceCapabilityArray
  );

  number = cleanPhoneNumber(number);

  // Whenever we get caps for a number, we invalidate our internal cache for it
  await invalidateCapsCache(number);

  defaultStore.set(capabilitiesAtom, {
    ...(defaultStore.get(capabilitiesAtom) || {}),
    [number]: serviceCapabilityArray || [],
  });
}
