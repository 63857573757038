import { useCallback, useState } from "react";

export const useMessageActions = () => {
  const [openActionsId, setOpenActionsId] = useState<string | null>(null);
  const [emojiPicker, setEmojiPicker] = useState(false);

  const closeActions = useCallback(() => {
    toggleEmojiPicker();
    setOpenActionsId(null);
  }, []);

  const toggleEmojiPicker = useCallback((e?) => {
    e?.stopPropagation();
    setEmojiPicker((prev) => !prev);
  }, []);

  return {
    openActionsId,
    setOpenActionsId,
    closeActions,
    toggleEmojiPicker,
    emojiPicker,
  };
};
