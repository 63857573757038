import { CallLogPayload } from "../../components/recentCallScreen/CallHistory";
import WebGwContact from "../helpers/WebGwContact";
import NmsMessage from "../messaging/NmsMessage";
import { callsState } from "./callAtoms";

/**
 * valtio takes over this class since ``Call``s are placed inside a proxyMap.
 * Making changes to internal properties will update the conversation's state and cause a rerender.
 */
export default class Call {
  public callId!: string;
  public remote!: string;
  public callLogPayload!: CallLogPayload;
  public isRead!: boolean;

  constructor(
    public peer: WebGwContact,
    public message: NmsMessage
  ) {
    this.construct();
  }

  private construct() {
    if (this.message["imdn.Message-ID"]) {
      this.callId = this.message["imdn.Message-ID"];
    }

    if (this.message.Direction === "In") {
      this.remote = this.message.From;
    } else {
      this.remote = this.message.To;
    }

    this.callLogPayload = this.message.getCallLog()!;

    this.isRead = !!this.message.flags?.flag?.find((flag) => flag === "\\Seen");

    return this;
  }

  public get callLength() {
    if (
      this.callLogPayload?.end_call_timestamp &&
      this.callLogPayload?.start_call_timestamp
    ) {
      return Math.max(
        0,
        this.callLogPayload.end_call_timestamp -
          this.callLogPayload.start_call_timestamp
      );
    }
    return 0;
  }

  public serialize() {
    return {
      peer: this.peer,
      message: this.message,
      isRead: this.isRead,
    };
  }

  public static from(obj: any) {
    const call = Object.assign(Object.create(Call.prototype), obj) as Call;

    if (obj.message && typeof obj.message === "object") {
      call.message = NmsMessage.from(obj.message);
    }

    if (obj.peer && typeof obj.peer === "object") {
      call.peer = WebGwContact.from(obj.peer);
    }

    call.construct();

    return call;
  }

  public static create(contact: WebGwContact | undefined, message: NmsMessage) {
    if (!contact || !message.getCallLog()) {
      return;
    }
    const messageId = message["imdn.Message-ID"];

    callsState.calls.set(messageId, new Call(contact, message));
    callsState.mapVersion++;
  }
}
