import { keyframes } from "@emotion/react";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useRef,
} from "react";
import { Renderable, Toast, ToastPosition, toast } from "react-hot-toast";

type ToastContextType = {
  showToast: ShowToast;
};

export type ShowToast = (
  message: ReactNode,
  position?: ToastPosition,
  fontSize?: string,
  icon?: Renderable
) => void;

type ToastMessage = {
  id: string;
  message: ReactNode;
};

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

export const ToastProvider = ({ children }) => {
  const toasts = useRef<ToastMessage[]>([]);

  const showToast: ToastContextType["showToast"] = useCallback(
    (message, position, fontSize, icon) => {
      const existingToastIndex = toasts.current.findIndex(
        (t) => t.message === message
      );

      if (existingToastIndex !== -1) {
        toast.dismiss(toasts.current[existingToastIndex].id);
        toasts.current = toasts.current.filter(
          (_, index) => index !== existingToastIndex
        );
      }

      if (toasts.current.length >= 3) {
        toast.dismiss(toasts.current[0].id);
        toasts.current = toasts.current.slice(1);
      }

      const newToast = toast.custom(
        (t) => (
          <MessageAnimation toast={t} fontSize={fontSize} message={message} />
        ),
        {
          duration: 5000,
          position: position || "top-center",
          icon: icon || undefined,
        }
      );

      toasts.current.push({ id: newToast, message });
    },
    []
  );

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
    </ToastContext.Provider>
  );
};

function MessageAnimation({
  message,
  toast: t,
  fontSize,
  position,
}: {
  message: ReactNode;
  toast: Toast;
  fontSize?: string;
  position?: ToastPosition;
}) {
  return (
    <div
      css={{
        color: "#FFFFFF",
        backgroundColor: "#2E3237",

        display: "flex",
        alignItems: "center",
        lineHeight: "1.3",
        boxShadow:
          "0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05)",
        maxWidth: "350px",
        pointerEvents: "auto",
        padding: "8px 10px",
        borderRadius: "8px",

        justifyContent: "center",
        margin: "4px 10px",
        flex: "1 1 auto",
        whiteSpace: "pre-line",

        fontSize: fontSize || "1em",
        animation: position?.endsWith("right")
          ? t.visible
            ? `${keyframes({
                "0%": { transform: "translateX(calc(100% + 16px))" },
                "100%": { transform: "translateX(0)" },
              })} 0.25s ease forwards`
            : `${keyframes({
                "0%": { transform: "translateX(0)" },
                "100%": { transform: "translateX(calc(100% + 16px))" },
              })} 0.25s ease forwards`
          : t.visible
            ? `${keyframes({
                "0%": { opacity: "0" },
                "100%": { opacity: "1" },
              })} 0.25s ease forwards`
            : `${keyframes({
                "0%": { opacity: "1" },
                "100%": { opacity: "0" },
              })} 0.25s ease forwards`,
      }}
    >
      {message}
    </div>
  );
}
