import { resetDocumentTitle } from "@/components/DocumentTitle";
import type { OdienceEvent } from "@/components/odience/utils/useDirectorAuthentication";
import { AES, enc } from "crypto-js";
import { milliseconds } from "date-fns/milliseconds";
import { DEFAULT_COUNTRY } from "../constants";
import type { UserLocationCountry } from "./loginAndCaps";

//TODO: Move this key to a query instead.
const secretKey = "vQAw160|E]MKGN0[2U|.";

function encryptItem(value: any) {
  const stringValue = value;
  const encryptedValue = AES.encrypt(stringValue, secretKey).toString();
  return encryptedValue;
}

function decryptItem(encryptedvalue: string) {
  const decryptedBytes = AES.decrypt(encryptedvalue, secretKey);
  const decryptedValue = decryptedBytes.toString(enc.Utf8);
  return decryptedValue;
}

const localUserLocationCountryKey = "localUserLocationCountryKey";
export function getUserLocationCountry() {
  const localCountry = localStorage.getItem(localUserLocationCountryKey);
  if (localCountry) {
    return JSON.parse(localCountry) as UserLocationCountry;
  } else {
    return DEFAULT_COUNTRY;
  }
}
export function setUserLocationCountry(country: UserLocationCountry) {
  localStorage.setItem(localUserLocationCountryKey, JSON.stringify(country));
}
export function removeUserLocationCountry() {
  localStorage.removeItem(localUserLocationCountryKey);
}

const localOdienceUserKey = "odienceUser";
export function getLocalOdienceUser() {
  return localStorage.getItem(localOdienceUserKey);
}
export function setLocalOdienceUser(user: string) {
  localStorage.setItem(localOdienceUserKey, user);
}
export function removeLocalOdienceUser() {
  localStorage.removeItem(localOdienceUserKey);
}

const localIsFirstTimeUserKey = "isFirstTimeUser";
export function getLocalIsFirstTimeUser() {
  return localStorage.getItem(localIsFirstTimeUserKey);
}
export function setLocalIsFirstTimeUser() {
  localStorage.setItem(localIsFirstTimeUserKey, "1");
}
export function removeLocalIsFirstTimeUser() {
  localStorage.removeItem(localIsFirstTimeUserKey);
}

const localDirectorTokenKey = "directorToken";
export function getLocalDirectorToken() {
  const encryptedLocalDirectorToken = localStorage.getItem(
    localDirectorTokenKey
  );
  if (encryptedLocalDirectorToken) {
    const decryptedLocalDirectorToken = decryptItem(
      encryptedLocalDirectorToken
    );
    return decryptedLocalDirectorToken;
  }
  return "";
}
export function setLocalDirectorToken(localDirectorToken: string) {
  const encryptedLocalDirectorToken = encryptItem(localDirectorToken);
  localStorage.setItem(localDirectorTokenKey, encryptedLocalDirectorToken);
}
export function removeLocalDirectorToken() {
  localStorage.removeItem(localDirectorTokenKey);
}

const localDirectorRefreshTokenKey = "directorRefreshToken";
export function setLocalDirectorRefreshToken(directorRefreshToken: string) {
  const encryptedDirectorRefreshToken = encryptItem(directorRefreshToken);
  localStorage.setItem(
    localDirectorRefreshTokenKey,
    encryptedDirectorRefreshToken
  );
}
export function getLocalDirectorRefreshToken(): string {
  const encryptedLocalDirectorRefreshToken = localStorage.getItem(
    localDirectorRefreshTokenKey
  );
  if (encryptedLocalDirectorRefreshToken) {
    const directorRefreshToken = decryptItem(
      encryptedLocalDirectorRefreshToken
    );
    return directorRefreshToken;
  }
  return "";
}
export function removeLocalDirectorRefreshToken() {
  localStorage.removeItem(localDirectorRefreshTokenKey);
}

const localAccessTokenKey = "accessToken";
export function getLocalAccessToken() {
  return localStorage.getItem(localAccessTokenKey);
}
export function setLocalAccessToken(accessToken: string) {
  localStorage.setItem(localAccessTokenKey, accessToken);
}
export function removeLocalAccessToken() {
  localStorage.removeItem(localAccessTokenKey);
}
export function initReloadOnAccessTokenChange() {
  window.addEventListener("storage", (event) => {
    if (event.key === localAccessTokenKey && !event.newValue) {
      clearLocalStorage();
      window.location.replace("");
    }
  });
}

const localUserFirstNameKey = "localUserFirstName";
export function setLocalUserFirstName(firstName: string) {
  localStorage.setItem(localUserFirstNameKey, firstName);
}
export function getLocalUserFirstName() {
  return localStorage.getItem(localUserFirstNameKey) ?? "";
}
export function removeLocalUserFirstName() {
  localStorage.removeItem(localUserFirstNameKey);
}

const localUserLastNameKey = "localUserLastName";
export function setLocalUserLastName(lastName: string) {
  localStorage.setItem(localUserLastNameKey, lastName);
}
export function getLocalUserLastName() {
  return localStorage.getItem(localUserLastNameKey) ?? "";
}
export function removeLocalUserLastName() {
  localStorage.removeItem(localUserLastNameKey);
}

const userKey = "user";
export function getLocalUser() {
  return localStorage.getItem(userKey);
}
export function setLocalUser(user: string) {
  localStorage.setItem(userKey, user);
}
export function removeLocalUser() {
  localStorage.removeItem(userKey);
}

const deviceNameKey = "deviceName";
export function getDeviceName() {
  return localStorage.getItem(deviceNameKey);
}
export function setDeviceName(deviceName: string) {
  localStorage.setItem(deviceNameKey, deviceName);
}
export function removeDeviceName() {
  localStorage.removeItem(deviceNameKey);
}

const sipUserKey = "sipuser";
export function getSipUser() {
  return localStorage.getItem(sipUserKey);
}
export function setSipUser(user: string) {
  localStorage.setItem(sipUserKey, user);
}
export function removeSipUser() {
  localStorage.removeItem(sipUserKey);
}

const messagesCursorKey = "messagesCursor";
export function getLocalMessagesCursor() {
  return localStorage.getItem(messagesCursorKey);
}
export function setLocalMessagesCursor(cursor: string) {
  localStorage.setItem(messagesCursorKey, cursor);
}
export function removeLocalMessagesCursor() {
  localStorage.removeItem(messagesCursorKey);
}

const impiKey = "impi";
export function setImpi(impi: string) {
  const encryptedImpi = encryptItem(impi);
  localStorage.setItem(impiKey, encryptedImpi);
}
export function getImpi(): string | null {
  const encryptedImpi = localStorage.getItem(impiKey);
  if (encryptedImpi) {
    const decryptedImpi = decryptItem(encryptedImpi);
    return decryptedImpi;
  }
  return null;
}
export function removeImpi() {
  localStorage.removeItem(impiKey);
}

const showProfileScreenKey = "showProfileScreenKey";
export function setShowProfileScreen(show: boolean) {
  localStorage.setItem(showProfileScreenKey, show.toString());
}
export function getShowProfileScreen(): boolean {
  return localStorage.getItem(showProfileScreenKey) === "true";
}
export function removeShowProfileScreen() {
  localStorage.removeItem(showProfileScreenKey);
}

const usernameKey = "username";
export function setUsername(username: string) {
  const encryptedUsername = encryptItem(username);
  localStorage.setItem(usernameKey, encryptedUsername);
}
export function getUsername(): string | null {
  const encryptedUsername = localStorage.getItem(usernameKey);
  if (encryptedUsername) {
    const decryptedUsername = decryptItem(encryptedUsername);
    return decryptedUsername;
  }
  return null;
}
export function removeUsername() {
  localStorage.removeItem(usernameKey);
}

const passwordKey = "password";
export function setPassword(password: string) {
  const encryptedPassword = encryptItem(password);
  localStorage.setItem(passwordKey, encryptedPassword);
}
export function getPassword(): string | null {
  const encryptedPassword = localStorage.getItem(passwordKey);
  if (encryptedPassword) {
    const decryptedPassword = decryptItem(encryptedPassword);
    return decryptedPassword;
  }
  return null;
}
export function removePassword() {
  localStorage.removeItem(passwordKey);
}

const nmsChannelUrlKey = "nmschannelurl";
export function setNmsChannelUrl(nmsChannelUrl: string) {
  const encryptedNmsChannelUrl = encryptItem(nmsChannelUrl);
  localStorage.setItem(nmsChannelUrlKey, encryptedNmsChannelUrl);
}
export function getNmsChannelUrl(): string | null {
  const encryptedNmsChannelUrl = localStorage.getItem(nmsChannelUrlKey);
  if (encryptedNmsChannelUrl) {
    const decryptedNmsChannelUrl = decryptItem(encryptedNmsChannelUrl);
    return decryptedNmsChannelUrl;
  }
  return null;
}
export function removeNmsChannelUrl() {
  localStorage.removeItem(nmsChannelUrlKey);
}

const nmsSubscriptionUrlKey = "nmssubscriptionurl";
export function setNmsSubscriptionUrl(nmsSubscriptionUrl: string) {
  const encryptedNmsSubscriptionUrl = encryptItem(nmsSubscriptionUrl);
  localStorage.setItem(nmsSubscriptionUrlKey, encryptedNmsSubscriptionUrl);
}
export function getNmsSubscriptionUrl(): string | null {
  const encryptedNmsSubscriptionUrl = localStorage.getItem(
    nmsSubscriptionUrlKey
  );
  if (encryptedNmsSubscriptionUrl) {
    const decryptedNmsSubscriptionUrl = decryptItem(
      encryptedNmsSubscriptionUrl
    );
    return decryptedNmsSubscriptionUrl;
  }
  return null;
}
export function removeNmsSubscriptionUrl() {
  localStorage.removeItem(nmsSubscriptionUrlKey);
}

const odienceEventsKey = "odienceEvents";
export function setLocalOdienceEvents(events: OdienceEvent[]) {
  setLocalStorageWithExpiry(
    odienceEventsKey,
    JSON.stringify(events),
    milliseconds({ days: 1 })
  );
}

export function getLocalOdienceEvents() {
  const localEvents = getLocalStorageWithExpiry(odienceEventsKey);
  if (localEvents) {
    return JSON.parse(localEvents) as OdienceEvent[];
  }
  return null;
}

const odienceOrganizationId = "organizationId";
export function setLocalOrganizationId(organizationId: string) {
  localStorage.setItem(odienceOrganizationId, organizationId);
}

export function getLocalOrganizationId() {
  return localStorage.getItem(odienceOrganizationId);
}

export function removeLocalOrganizationId() {
  localStorage.removeItem(odienceOrganizationId);
}

/**
 * Sets a value in the local storage with an expiry time.
 * @param key - The key to set in the local storage.
 * @param value - The value to set in the local storage.
 * @param expiry - The expiry time in milliseconds.
 */
function setLocalStorageWithExpiry(key: string, value: string, expiry: number) {
  const expiryTime = Date.now() + expiry;
  localStorage.setItem(key, JSON.stringify({ value, expiry: expiryTime }));
}

function getLocalStorageWithExpiry(key: string) {
  const localStorageItem = localStorage.getItem(key);
  if (localStorageItem) {
    const localStorageItemJson = JSON.parse(localStorageItem);
    if (localStorageItemJson.expiry > Date.now()) {
      return localStorageItemJson.value;
    } else {
      localStorage.removeItem(key);
    }
  }
  return null;
}

/**
 * Call this when logging out.
 */
export function clearLocalStorage() {
  const organizationId = getLocalOrganizationId();

  localStorage.clear();

  if (organizationId) {
    setLocalOrganizationId(organizationId);
  }

  sessionStorage.clear();

  resetDocumentTitle();
}
